import PackageJson from "../../../package.json";
import {
  HOME,
  LEARN,
  LOGIN,
  REGISTER,
  ROADMAP,
  VOCABULARY,
} from "../../routing/routes";
import { MenuButton } from "../ui/buttons/MenuButton";
import { IOsAddToHomescreenInstructionModal } from "./IOsAddToHomescreenInstructionModal";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import {
  Divider,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../hooks/use-user";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import { useNavigate } from "react-router-dom";
import { useInstallationPromptEvent } from "../../utils/installation-provider";
import { ChromeButton } from "../ui/buttons/ChromeButton";
import FiberNewIcon from "@mui/icons-material/FiberNew";

const SpaceBetweenColumnContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

const VersionNumber = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  width: "100%",
}));

interface Props {
  closeDrawer?: () => void;
}

export const SideMenuContent = ({ closeDrawer }: Props) => {
  const pwaInstallEvent = useInstallationPromptEvent();

  console.log("pwaInstallEvent.value", pwaInstallEvent.value);
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    isIOsAddToHomescreenInstructionModalOpen,
    setIsIOsAddToHomescreenInstructionModalOpen,
  ] = useState<boolean>(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <SpaceBetweenColumnContainer>
      <div>
        {user ? (
          <>
            <MenuButton
              startIcon={<HomeRoundedIcon />}
              to={HOME}
              variant={"text"}
              end
              onClick={closeDrawer}
            >
              {t("page.home.sideMenu")}
            </MenuButton>
            <MenuButton
              startIcon={<LayersRoundedIcon />}
              to={VOCABULARY}
              variant={"text"}
              end
              onClick={closeDrawer}
            >
              {t("page.vocabulary.sideMenu")}
            </MenuButton>
            <MenuButton
              startIcon={<SchoolRoundedIcon />}
              to={LEARN}
              variant={"text"}
              end
              onClick={closeDrawer}
            >
              {t("page.learnMode.sideMenu")}
            </MenuButton>
            <MenuButton
              startIcon={<FiberNewIcon />}
              variant={"text"}
              to={ROADMAP}
              end
              onClick={() => {
                closeDrawer && closeDrawer();
              }}
            >
              {t("components.sideMenu.feedback")}
            </MenuButton>
          </>
        ) : (
          <div style={{ display: "flex", gap: 8 }}>
            {/*todo double in code*/}

            <PrimaryButton
              onClick={() => {
                navigate(REGISTER);
                closeDrawer && closeDrawer();
              }}
              size={isMdUp ? "medium" : "small"}
            >
              {t("page.website.header.register")}
            </PrimaryButton>

            <PrimaryButton
              onClick={() => {
                navigate(LOGIN);
                closeDrawer && closeDrawer();
              }}
              size={isMdUp ? "medium" : "small"}
              variant={"outlined"}
            >
              {t("page.website.header.login")}
            </PrimaryButton>
          </div>
        )}
        <StyledDivider />
        {/* todo show if InstallationEvent exists or if its on safari mobile todo later*/}

        <MenuButton
          startIcon={<AddToHomeScreenIcon />}
          onClick={(e) => {
            if (pwaInstallEvent.value) {
              try {
                pwaInstallEvent.value.prompt();
              } catch (err) {
                console.log("Error: ", err);
                setIsIOsAddToHomescreenInstructionModalOpen(true);
              }
            } else {
              setIsIOsAddToHomescreenInstructionModalOpen(true);
            }
          }}
        >
          {t("components.sideMenu.addToHomescreen")}
        </MenuButton>

        <ChromeButton small fullWidth />
      </div>
      <div>
        <Typography variant={"body1"}>
          {t("components.sideMenu.support")}
        </Typography>
        <PrimaryButton
          onClick={() => (window.location.href = "mailto:info@vocabolino.com")}
          variant={"text"}
        >
          {t("components.sideMenu.btnSupport")}
        </PrimaryButton>
      </div>
      <div>
        <VersionNumber
          variant={"caption"}
          color={"lightgray"}
        >{`v${PackageJson.version}`}</VersionNumber>
      </div>
      <IOsAddToHomescreenInstructionModal
        onOpen={isIOsAddToHomescreenInstructionModalOpen}
        onClose={() => {
          setIsIOsAddToHomescreenInstructionModalOpen(false);
          closeDrawer && closeDrawer();
        }}
      />
    </SpaceBetweenColumnContainer>
  );
};
