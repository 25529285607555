import React from "react";
import { useTranslation } from "react-i18next";
import { MotherTongueChangeDropdown } from "../MotherTongueChangeDropdown/MotherTongueChangeDropdown";
import { SettingItem } from "./SettingItem";

interface Props {}
export const MotherTongueSetting = ({}: Props) => {
  const { t } = useTranslation();

  return (
    <SettingItem name={t("dialog.settings.motherTongue")}>
      <MotherTongueChangeDropdown />
    </SettingItem>
  );
};
