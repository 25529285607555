import {
  Alert,
  alpha,
  Container,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Page } from "../../layout/layout/Page";
import CheckIcon from "@mui/icons-material/Check";
import EastIcon from "@mui/icons-material/East";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import screen_home from "./images/screen_home.png";
import screen_read from "./images/screen_read.png";
import screen_learn_mode from "./images/screen_learn_mode.png";
import { REGISTER } from "../../routing/routes";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import video from "./images/hellovocabs2.gif";
import videoPreview from "./images/hellovocabs2_preview.png";
import main from "./images/main.png";
import Flag from "national-flag-icons";
import {
  InfoCard,
  InfoCardText,
  Section,
  StyledChipSecondary,
  StyledInfoContainer,
} from "../page-styles";

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
}));

const FeatureCard = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",

  minWidth: 300,
  borderColor: theme.palette.gray.main,
  padding: theme.spacing(4),

  textAlign: "left",
  filter: "drop-shadow(0px 4px 30px #eaedef)",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 500,
    width: "45%",
  },
}));

const FeatureList = styled("ul")(({ theme }) => ({
  listStyleType: "disc",
  marginLeft: theme.spacing(3),
  listStyle: "none",
}));

const FeatureListItem = styled("li")(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const FeatureContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(6),
  padding: theme.spacing(5),
  marginTop: theme.spacing(9),
  flexWrap: "wrap",
}));

/*todo translate comming soon*/
const ComingSoon = styled("div")(({ theme }) => ({
  "&::before": {
    content: '"Coming soon"',
    fontFamily: "Poppins-Regular",
    fontSize: theme.typography.pxToRem(12), // Adjust the font size as needed
    color: "#fff",

    background: theme.palette.secondary.main, // Use your cta-color from the theme
    position: "absolute",
    top: 0,
    right: theme.spacing(3),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.3)} ${theme.spacing(
      0.8
    )}`,
    borderRadius: `0 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  },
}));
const ContainerMain = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  paddingBottom: 30,
  [theme.breakpoints.up("md")]: {
    maxWidth: "60%",
  },
}));

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.paper,
  padding: theme.spacing(5, 4),
  gap: theme.spacing(1),
  [theme.breakpoints.up("md")]: {},
}));
const MainImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
}));

const LanguageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  padding: 8,
}));

const Popper1 = styled("div")(({ theme }) => ({
  position: "absolute",
  padding: 20,
  textAlign: "center",
  display: "flex",
  color: "white",
  justifyContent: "center",
  alignItems: "center",
  left: "25%",
  top: 0,
  width: 100,
  transform: "rotateZ(15)",
  height: 100,
  fontSize: "1rem",
  borderRadius: "100%",
  backgroundColor: theme.palette.tertiary.main,
  [theme.breakpoints.up("md")]: {
    width: 250,
    height: 250,
    fontSize: "2rem",
  },
}));

const Popper2 = styled("div")(({ theme }) => ({
  position: "absolute",
  padding: 20,
  textAlign: "center",
  display: "flex",
  color: "white",
  justifyContent: "center",
  alignItems: "center",
  right: 130,
  top: "80%",
  width: 100,
  height: 100,
  fontSize: "1rem",
  borderRadius: "100%",
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.up("md")]: {
    width: 250,
    height: 250,
    fontSize: "2rem",
  },
}));

interface Props {}
export const WebsitePage = ({}: Props) => {
  const theme = useTheme();
  const [isGifVisible, setIsGifVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const features = i18n.t("page.website.features", { returnObjects: true }) as {
    title: string;
    points: string[];
    comingSoon: boolean;
  }[];

  const navigate = useNavigate();
  return (
    <Page>
      <Section>
        <ContainerMain>
          <Typography variant={"h1"} align={"center"}>
            <mark> {t("page.website.section1.markedHeadline")}</mark>{" "}
            {t("page.website.section1.headline")}
          </Typography>
          <Typography variant={"body2"} align={"center"} pt={1}>
            {t("page.website.section1.subHeadline")}
          </Typography>
          <Alert style={{ marginTop: 30 }} severity="error">
            {t("test.register.info")}
          </Alert>
          <PrimaryButton
            onClick={() => navigate(REGISTER)}
            style={{ marginTop: 30 }}
            endIcon={<EastIcon />}
          >
            {t("page.website.section1.cta")}
          </PrimaryButton>

          {/*<ChromeButton />*/}
        </ContainerMain>
      </Section>
      <Section>
        <StyledInfoContainer>
          <MainImageContainer>
            <StyledImage src={main} alt="Example GIF" />
            <Popper1>
              <Typography
                style={{
                  color: "inherit",
                  fontSize: "inherit",
                }}
                variant={"h2"}
              >
                {t("page.website.popper1")}
              </Typography>
            </Popper1>
            <Popper2>
              <Typography
                style={{
                  color: "inherit",
                  fontSize: "inherit",
                }}
                variant={"h2"}
              >
                {t("page.website.popper2")}
              </Typography>
            </Popper2>
          </MainImageContainer>
        </StyledInfoContainer>
      </Section>
      {/*  <InfoCard>
        <StyledImage
          style={{ maxWidth: 900, textAlign: "center" }}
          src={languages}
        />
      </InfoCard>*/}
      <Section>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledChipSecondary
            label={t("page.website.languages.label")}
            size={"small"}
          />

          <Typography variant={"h1"} align={"center"}>
            {t("page.website.languages.headline")}
          </Typography>

          <div>
            <LanguageContainer>
              <Flag flagCode={"de"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.de`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"gb"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.en`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"es"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.es`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"it"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.it`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"pt"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.pt`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"fr"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.fr`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Flag flagCode={"sk"} height={16} style={{ marginRight: 5 }} />
              <Typography>{t(`languageCode.sk`)}</Typography>
            </LanguageContainer>
            <LanguageContainer>
              <Typography>{t(`...`)}</Typography>
            </LanguageContainer>
          </div>
          <Typography variant={"body2"} align={"center"}>
            {t("page.website.languages.subHeadline")}
          </Typography>
          <PrimaryButton
            onClick={() =>
              (window.location.href = "mailto:info@vocabolino.com")
            }
            variant={"text"}
          >
            {t("components.sideMenu.btnSupport")}
          </PrimaryButton>
        </Container>
      </Section>
      <Section>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledChipSecondary
            label={t("page.website.video.label")}
            size={"small"}
          />

          <Typography variant={"h1"} align={"center"}>
            {t("page.website.video.headline")}
          </Typography>

          <StyledInfoContainer>
            {isGifVisible ? (
              <StyledImage src={video} alt="Example GIF" />
            ) : (
              <StyledImage
                onClick={() => setIsGifVisible(true)}
                src={videoPreview}
                alt="Example GIF"
              />
            )}
          </StyledInfoContainer>
        </Container>
      </Section>
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledChipSecondary
            label={t("page.website.featuresPage.label")}
            size={"small"}
          />

          <Typography variant={"h1"} align={"center"}>
            {t("page.website.featuresPage.headline")}
          </Typography>
          <Typography variant={"body2"} align={"center"}>
            {t("page.website.featuresPage.subHeadline")}
          </Typography>
        </Container>
        <FeatureContainer>
          <>
            {features.map((feature, index) => {
              const points = feature.points;
              if (feature.comingSoon === true) {
                return (
                  <FeatureCard
                    key={index}
                    style={{
                      border: "2px solid",
                      borderColor: theme.palette.secondary.light,
                      position: "relative",
                    }}
                  >
                    <ComingSoon />
                    <Typography
                      variant={"h3"}
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      {feature.title}
                    </Typography>
                    {points.map((point, index) => {
                      return (
                        <ListItem key={index} style={{ padding: 4 }}>
                          <CheckIcon
                            style={{
                              fontSize: 20,
                              marginRight: 10,
                              fontWeight: "bold",
                              color: theme.palette.secondary.light,
                            }}
                          />

                          <Typography style={{ padding: 0 }} variant={"body2"}>
                            {point}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </FeatureCard>
                );
              } else {
                return (
                  <FeatureCard>
                    <Typography
                      variant={"h3"}
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      {feature.title}
                    </Typography>
                    {points.map((point) => {
                      return (
                        <ListItem key={point} style={{ padding: 4 }}>
                          <ListItemIcon>
                            <CheckIcon
                              color={"info"}
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                color: theme.palette.secondary.light,
                              }}
                            />
                          </ListItemIcon>
                          <Typography style={{ padding: 0 }} variant={"body2"}>
                            {point}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </FeatureCard>
                );
              }
            })}
          </>
        </FeatureContainer>
      </Section>
      <Section
        style={{ backgroundColor: alpha(theme.palette.tertiary.light, 0.2) }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 50,
            }}
          >
            <StyledChipSecondary label={"HOW IT WORKS"} size={"small"} />
            {/*todonext übersetzen*/}
            <Typography variant={"h1"} align={"center"}>
              {t("page.website.howItWorks.headline.part1")}{" "}
              {t("page.website.howItWorks.headline.part2")}
            </Typography>
            <Typography variant={"body2"} align={"center"}>
              {t("page.website.howItWorks.subHeadline")}
            </Typography>
          </div>
          <StyledInfoContainer>
            <InfoCard>
              <InfoCardText>
                <Typography variant={"h2"} align={"center"}>
                  {t("page.website.howItWorks.part1.headline")}
                </Typography>
                <Typography variant={"body2"} align={"center"}>
                  {t("page.website.howItWorks.part1.subHeadline")}
                </Typography>
              </InfoCardText>
              <StyledImage src={screen_read} />
            </InfoCard>
          </StyledInfoContainer>
        </Container>
      </Section>
      <Section
        style={{ backgroundColor: alpha(theme.palette.secondary.light, 0.2) }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledInfoContainer>
            <InfoCard>
              <InfoCardText>
                <Typography variant={"h2"} align={"center"}>
                  {t("page.website.howItWorks.part2.headline")}
                </Typography>
                <Typography variant={"body2"} align={"center"}>
                  {t("page.website.howItWorks.part2.subHeadline")}
                </Typography>
              </InfoCardText>
              <StyledImage src={screen_home} />
            </InfoCard>
          </StyledInfoContainer>
        </Container>
      </Section>
      <Section style={{ backgroundColor: alpha("#f59447", 0.1) }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledInfoContainer>
            <InfoCard>
              <InfoCardText>
                <Typography variant={"h2"} align={"center"}>
                  {t("page.website.howItWorks.part3.headline")}
                </Typography>
                <Typography variant={"body2"} align={"center"}>
                  {t("page.website.howItWorks.part3.subHeadline")}
                </Typography>
              </InfoCardText>
              <StyledImage src={screen_learn_mode} />
            </InfoCard>

            <InfoCard style={{ opacity: 0.5 }}>
              <InfoCardText>
                <Typography variant={"h3"} align={"center"}>
                  {t("page.website.howItWorks.part4.headline")}
                </Typography>
              </InfoCardText>
            </InfoCard>
            <InfoCard style={{ opacity: 0.5 }}>
              <InfoCardText>
                <Typography variant={"h3"} align={"center"}>
                  {t("page.website.howItWorks.part5.headline")}
                </Typography>
              </InfoCardText>
            </InfoCard>
            <InfoCard style={{ opacity: 0.5 }}>
              <InfoCardText>
                <Typography variant={"h3"} align={"center"}>
                  {t("page.website.howItWorks.part6.headline")}
                </Typography>
              </InfoCardText>
            </InfoCard>
          </StyledInfoContainer>
        </Container>
      </Section>
      <Footer>
        <Typography style={{ color: "inherit" }}>
          {t("page.website.copyRight")}
        </Typography>
        <div>
          <Link
            style={{ color: "inherit" }}
            to={"https://www.iubenda.com/privacy-policy/92448294"}
          >
            <Typography style={{ color: "inherit" }}>
              {t("page.website.imprint")}
            </Typography>
          </Link>
          <Link
            style={{ color: "inherit" }}
            to={"https://www.iubenda.com/privacy-policy/92448294"}
          >
            <Typography style={{ color: "inherit" }}>
              {t("page.website.privacyPolicy")}{" "}
            </Typography>
          </Link>
          {/* <Link
          style={{ color: "inherit" }}
          to={"https://www.iubenda.com/privacy-policy/92448294"}
        >
          AGBs
        </Link>*/}
        </div>
      </Footer>
    </Page>
  );
};
