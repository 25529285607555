import { PrimaryButton } from "@ppphiiil/mvp-system";
import React, { useEffect, useState } from "react";
import { Direction, Translation } from "../types/types";

import { LearnModeSettingsButton } from "./LearnModeSettings/LearnModeSettingsButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SwipeAnimation from "./SwipeAnimation/SwipeAnimation";
import { useFetchSetting } from "../hooks/use-fetch-setting/useFetchSetting";
import { FlashCard } from "./FlashCard";
import { LanguageCodes } from "./shared/LanguageButton/language-code/languages";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  gap: 10px;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export interface VocabularyListProps {
  translations: Translation[];
}

export interface SettingsLearnMode {
  direction: Direction;
}
const VocabularyList: React.FC<VocabularyListProps> = ({ translations }) => {
  const [motherTongue, setMotherTongue] = useState<string | null>(null);
  const {
    mutate: fetchSetting,
    error,
    isPending,
  } = useFetchSetting<"motherTongue">((motherTongue) => {
    setMotherTongue(motherTongue);
  });

  const translationDirections =
    motherTongue === null
      ? getTranslationDirections(translations)
      : sortDirections(getTranslationDirections(translations), motherTongue);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [settings, setSettings] = useState<SettingsLearnMode>({
    direction: translationDirections[0],
  });

  const { t } = useTranslation();

  useEffect(() => {
    fetchSetting("motherTongue");
  }, [translations]);

  const handleSwipeRight = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < translations.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleSwipeLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleSettings = (settings: SettingsLearnMode) => {
    setSettings(settings);
  };

  const isMobile = window.innerWidth <= 768; // Define the mobile width breakpoint

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",
        height:
          "80%" /*todo hack that the card page is nicht so groß damit scroll, scroll nervt auf der seite*/,
      }}
    >
      <SettingsContainer>
        <LearnModeSettingsButton
          onChangeSettings={handleSettings}
          translationDirections={translationDirections}
        />
      </SettingsContainer>
      {/*todo sort direction on server*/}
      <FlashCard
        key={currentIndex}
        word={
          translations[currentIndex].wordLang ===
          (settings.direction.from as LanguageCodes)
            ? translations[currentIndex].word
            : translations[currentIndex].translation
        }
        wordLang={
          translations[currentIndex].wordLang ===
          (settings.direction.from as LanguageCodes)
            ? translations[currentIndex].wordLang
            : translations[currentIndex].translationLang
        }
        translation={
          translations[currentIndex].translationLang ===
          (settings.direction.to as LanguageCodes)
            ? translations[currentIndex].translation
            : translations[currentIndex].word
        }
        translationLang={
          translations[currentIndex].wordLang ===
          (settings.direction.from as LanguageCodes)
            ? translations[currentIndex].translationLang
            : translations[currentIndex].wordLang
        }
        onSwipeLeft={handleSwipeLeft}
        onSwipeRight={handleSwipeRight}
      />
      {!isMobile ? (
        <ActionContainer>
          <PrimaryButton variant={"outlined"} onClick={handleSwipeLeft}>
            {t("page.learnMode.cardActions.back")}
          </PrimaryButton>
          <PrimaryButton variant={"outlined"} onClick={handleSwipeRight}>
            {t("page.learnMode.cardActions.next")}
          </PrimaryButton>
        </ActionContainer>
      ) : (
        <SwipeAnimation />
      )}
    </div>
  );
};

export default VocabularyList;

const getTranslationDirections = (translations: Translation[]): Direction[] => {
  let directionSet: Direction[] = [];

  translations.forEach((translation) => {
    // this is beacause at the beginning i had languagecode="word" instead of language code with length 2
    // so if its longer than 2 its a word and we ignore it
    if (
      translation.wordLang.length === 2 ||
      translation.translationLang.length === 2
    ) {
      let direction: Direction = {
        from: translation.wordLang,
        to: translation.translationLang,
      };
      let directionReverse: Direction = {
        from: translation.translationLang,
        to: translation.wordLang,
      };

      // Add the current direction if it's not already in the set
      if (
        !directionSet.some(
          /*todo overhead*/
          (dir) => dir.from === direction.from && dir.to === direction.to
        )
      ) {
        directionSet.push(direction);
        directionSet.push(directionReverse);
      }
    }
  });

  return directionSet;
};
const detectLanguages = (translations: Translation[]): LanguageCodes[] => {
  const detectedLanguages: LanguageCodes[] = [];
  translations.forEach((translation) => {
    if (
      !detectedLanguages.includes(translation.wordLang) &&
      translation.wordLang.length === 2
    ) {
      detectedLanguages.push(translation.wordLang);
    }
    if (
      !detectedLanguages.includes(translation.translationLang) &&
      translation.wordLang.length === 2
    ) {
      detectedLanguages.push(translation.translationLang);
    }
  });
  return detectedLanguages;
};

const sortDirections = (
  directions: Direction[],
  motherTongue: string
): Direction[] => {
  return directions.sort((a, b) => {
    if (a.from === motherTongue) {
      return -1;
    } else {
      return 1;
    }
  });
};
