import { useQuery } from "@tanstack/react-query";
import { Translation } from "../../types/types";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { useUser } from "../use-user";
import { LearnStatus } from "../../services/translations/translation-service";

export const useFetchTranslations = (filter?: LearnStatus) => {
  const { translationService } = useServices();
  const { user } = useUser();
  const auth = getAuth();
  return useQuery<Translation[], Error>({
    queryKey: ["translations"],
    queryFn: async () => {
      return await translationService.fetchTranslations(auth, filter);
    },
  });
};
