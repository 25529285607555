import React, { Context, useContext } from "react";
import { BeforeInstallPromptEvent } from "../../hooks/useBeforeInstallPrompzHandler";

const InstallationContext =
  React.createContext<BeforeInstallPromptEvent | null>(null);
InstallationContext.displayName = "InstallationContext";

interface Props {
  children: React.ReactNode;
  pwaInstallEvent: any;
}

export const InstallationProvider = ({ children, pwaInstallEvent }: Props) => {
  return (
    <InstallationContext.Provider value={pwaInstallEvent}>
      {children}
    </InstallationContext.Provider>
  );
};

export const useInstallationPromptEvent =
  createUseContextHooktwo(InstallationContext);

//double
export function createUseContextHooktwo<T>(
  theContext: Context<T | null>
): () => T {
  return () => {
    const context = useContext(theContext);
    if (context === null) {
      throw new Error(
        `${theContext.displayName} is null, wrap your component with a matching Provider`
      );
    }
    return context;
  };
}
