import { alpha, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  InfoCard,
  InfoCardText,
  Section,
  StyledChipBlue,
  StyledInfoContainer,
} from "../page-styles";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@ppphiiil/mvp-system";

interface Props {}
export const RoadmapPage = ({}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div>
      <Section
        style={{ backgroundColor: alpha(theme.palette.tertiary.light, 0.2) }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 50,
            }}
          >
            <StyledChipBlue label={t("page.roadmapPage.chip")} size={"small"} />
            {/*todonext übersetzen*/}
            <Typography variant={"h1"} align={"center"}>
              {t("page.roadmapPage.headline")}
            </Typography>
            <Typography variant={"body2"} align={"center"}>
              {t("page.roadmapPage.subHeadline")}
            </Typography>
          </div>
          <Section>
            <StyledInfoContainer>
              <InfoCard>
                <InfoCardText>
                  <Typography variant={"h2"} align={"center"}>
                    {t("page.roadmapPage.headline2")}
                  </Typography>
                </InfoCardText>
                <PrimaryButton
                  onClick={() => {
                    window.open(
                      "https://forms.clickup.com/2178981/f/22fx5-8275/O91MOFCH28ECHVRDD7"
                    );
                  }}
                >
                  {t("page.roadmapPage.cta2")}
                </PrimaryButton>
              </InfoCard>
            </StyledInfoContainer>
          </Section>
          <StyledInfoContainer>
            <InfoCard>
              <InfoCardText>
                <Typography variant={"h2"} align={"center"}>
                  {t("page.roadmapPage.headline3")}
                </Typography>
              </InfoCardText>
            </InfoCard>
            <iframe
              className="clickup-embed"
              src="https://sharing.clickup.com/2178981/l/h/22fx5-8255/2a202d47c1d6101"
              width="100%"
              height="700px"
              style={{
                background: "transparent",
                border: "1px solid #ccc",
              }}
            ></iframe>
          </StyledInfoContainer>
        </Container>
      </Section>
    </div>
  );
};
