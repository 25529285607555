import { useServices } from "../../services/service-provider";
import { useMutation } from "@tanstack/react-query";
import { AppError } from "@ppphiiil/mvp-system";
import { MVPErrorType } from "@ppphiiil/mvp-system/dist/services/app-error/error-types";

export const useLogout = () => {
  const { authService } = useServices();

  return useMutation<void, AppError<MVPErrorType>, void>({
    mutationFn: async () => {
      await localStorage.removeItem("token");
      return authService.logout();
    },
    onSuccess: () => {
      // Invalidate and refetch
      console.log("loggedout");
    },
  });
};
