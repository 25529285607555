import { AppError, AuthPage, Register } from "@ppphiiil/mvp-system";
import React, { useState } from "react";
import { API_URL } from "../../index";
import { HOME } from "../../routing/routes";
import { useNavigate } from "react-router-dom";
import { useServices } from "../../services/service-provider";
import { sendEmailVerification, User } from "@firebase/auth";
import { convertFirebaseErrorToAppError } from "../../components/ErrorMessage";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

export const RegisterPage = () => {
  const navigate = useNavigate();
  const { authService } = useServices();
  const [error, setError] = useState<AppError<any> | undefined>();
  const { t } = useTranslation();
  return (
    <AuthPage>
      <Alert severity="error">{t("test.register.info")}</Alert>
      <Register
        error={error}
        onSubmit={async (formValues) => {
          //await authService.register({ email: formValues.email, password: formValues.password })
          try {
            //todo in service machen
            const response: Response = await fetch(`${API_URL}/auth/register`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: formValues.email,
                password: formValues.password,
              }),
            });
            if (response.ok) {
              const user = await authService.login({
                email: formValues.email,
                password: formValues.password,
              });
              await sendEmailVerification(user as User, {
                url: `https://hello-vocabs.netlify.app${HOME}`,

                /*handleCodeInApp: true*/
              });
              navigate(HOME, { replace: true });
            } else {
              const data = await response.json();
              if (data.firebaseError) {
                setError(convertFirebaseErrorToAppError(data.error));
              }
            }
          } catch (e) {
            console.log("error while register", e);
          }
        }}
      />
    </AuthPage>
  );
};
