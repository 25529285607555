import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DialogContent, DialogContentText } from "@mui/material";

import { useServices } from "../../services/service-provider";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import { ServiceWorkerWrapper } from "../../services/service-worker/service-worker-wrapper";
import { Dialog } from "../dialog";

export const AppUpdateDialog = () => {
  const { t } = useTranslation();

  const { serviceWorkerService } = useServices();
  const [open, setOpen] = useState<boolean>(false);
  const [serviceWorkerWrapper, setServiceWorkerWrapper] = useState<
    ServiceWorkerWrapper | undefined
  >();

  React.useEffect(() => {
    return serviceWorkerService.registerListener((wrapper) => {
      setServiceWorkerWrapper(wrapper);
      setOpen(true);
    });
  }, [serviceWorkerService, open]);

  return (
    <Dialog
      title={t("components.appUpdateDialog.title")}
      isOpen={open}
      onClose={() => setOpen(false)}
      contentPadding={3}
      actions={() => (
        <>
          <PrimaryButton
            variant="outlined"
            color={"primary"}
            onClick={() => setOpen(false)}
          >
            {t("components.appUpdateDialog.btnCancel")}
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            onClick={() => serviceWorkerWrapper?.update()}
          >
            {t("components.appUpdateDialog.btnUpdate")}
          </PrimaryButton>
        </>
      )}
    >
      <DialogContent>
        <DialogContentText>
          {t("components.appUpdateDialog.description")}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
