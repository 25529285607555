import React, { useState } from "react";
import AddTranslationDialog from "../TranslationDialog/TranslationDialog";
import { useAddTranslation } from "../../hooks/use-add-translation/useAddTranslation";
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useTranslation } from "react-i18next";

interface Props {}
export const AddWordButton = ({}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { mutate: saveTranslation, isPending: isPendingAddTranslation } =
    useAddTranslation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    zIndex: 1000,
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.gray.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.gray.main,
      color: theme.palette.common.black,
      borderRadius: 5,
    },
  }));

  return (
    <div>
      <BootstrapTooltip
        title={t("components.addTranslationButton.addWordButtonTooltip")}
        enterDelay={500}
        leaveDelay={200}
        arrow
        open={isMdUp ?? true}
        placement={"left"}
      >
        <IconButton
          color={"primary"}
          onClick={() => setDialogOpen(true)}
          size="large"
          sx={{ padding: 0 }}
        >
          <AddCircleRoundedIcon fontSize="inherit" />
        </IconButton>
      </BootstrapTooltip>
      <AddTranslationDialog
        open={dialogOpen}
        isLoading={isPendingAddTranslation}
        handleClose={() => setDialogOpen(false)}
        onAddWord={(word, wordLang, translation, translationLang) =>
          saveTranslation({ word, wordLang, translation, translationLang })
        }
      />
    </div>
  );
};
