import { useUser } from "../hooks/use-user";
import { useServices } from "../services/service-provider";
import { alpha, styled } from "@mui/material/styles";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useFetchTranslations } from "../hooks/use-fetch-translations/useFetchTranslations";
import { useAddTranslation } from "../hooks/use-add-translation/useAddTranslation";
import { useDeleteTranslation } from "../hooks/use-delete-translation/useDeleteTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { LearnStatus } from "../services/translations/translation-service";
import { useTranslation } from "react-i18next";

const VocabularyList = styled("ul")({
  listStyleType: "none",
  padding: 0,
  margin: 0,
  height: 150,
});

const TranslationCard = styled("li")(() => ({
  backgroundColor: "white",
  borderRadius: "15px",
  width: "100%",
  height: "100%",
  border: "1px solid",
  borderColor: "#dedede",
  overflow: "hidden",
  marginBottom: "10px",
  fontSize: "1.3em",
}));

const VocabularyTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "bold",
  backgroundColor: alpha(theme.palette.primary.light, 0.06),
  overflow: "hidden",
  height: "100%",
  width: "100%",
  padding: "20px",
}));

const OverlayContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 100,
  left: 0,
  width: "100%",
  textAlign: "center",
  backgroundColor: "red",
}));

const VocabularyText = styled(Typography)(({}) => ({
  fontWeight: "bold",
  height: "100%",

  maxHeight: "3em" /* Adjust to fit approximately two rows of text */,
  overflow: "hidden",
  textOverflow: "ellipsis" /* Optional: Add ellipsis for overflowed text */,
  whiteSpace: "normal",
}));

const TranslatedTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "bold",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
  padding: "20px",
}));

const TranslatedText = styled(Typography)({
  height: "100%",
  maxHeight: "3em" /* Adjust to fit approximately two rows of text */,
  overflow: "hidden",
  textOverflow: "ellipsis" /* Optional: Add ellipsis for overflowed text */,
  whiteSpace: "normal",
});
const CardContent = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
}));

export const Page = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6),
  },
}));

interface FilterParams {
  filter: LearnStatus; // Define the type for 'filter'
  // Add more parameters if needed
}

export const VocabularySelectionPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const { user } = useUser();
  const { t } = useTranslation();
  const { filter } = useParams(); //todo learn useParams with TS
  const navigate = useNavigate();

  const { authService } = useServices();

  const { data: fetchedTranslations, isPending } = useFetchTranslations(
    filter as LearnStatus
  );
  const { mutate: addTranslation, isPending: isPendingAddTranslation } =
    useAddTranslation();
  const { mutate: deleteTranslation, isPending: isPendingDeleteTranslation } =
    useDeleteTranslation();

  return (
    <Page>
      <Typography variant={"h1"} style={{ paddingBottom: 30 }}>
        {filter && filter[0].toUpperCase() + filter.slice(1)}
      </Typography>

      <VocabularyList>
        {isPending && <CircularProgress />}
        {fetchedTranslations !== undefined &&
          fetchedTranslations.map((entry, index) => (
            <TranslationCard key={index}>
              <CardContent>
                <VocabularyTextContainer>
                  <VocabularyText>{`${entry.word}`}</VocabularyText>
                </VocabularyTextContainer>
                <TranslatedTextContainer>
                  <TranslatedText>{`${entry.translation}`}</TranslatedText>
                  <PrimaryButton
                    variant="outlined"
                    loading={isPendingDeleteTranslation}
                    size={"small"}
                    onClick={(e) => {
                      entry.id && deleteTranslation(entry.id);
                    }}
                  >
                    {t("components.card.delete")}
                  </PrimaryButton>
                </TranslatedTextContainer>
              </CardContent>
            </TranslationCard>
          ))}
      </VocabularyList>
    </Page>
  );
};
