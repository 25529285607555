import { useMutation } from "@tanstack/react-query";
import { getAuth } from "@firebase/auth";
import { useServices } from "../../services/service-provider";

//todo sprachen in ein enum?!
export interface Settings {
  motherTongue: string;
  learnLanguage: string;
  isOnboardingFinished: boolean;
}
export const useFetchSetting = <T extends keyof Settings>(
  onSuccess?: (motherTongue: Settings[T]) => void
) => {
  const auth = getAuth();
  const { translationService, authService } = useServices();
  return useMutation<Settings[T], Error, T>({
    mutationFn: async (settingName) => {
      return await translationService.fetchSetting(auth, settingName);
    },
    onSuccess: (motherTongue) => onSuccess && onSuccess(motherTongue),
  });
};
