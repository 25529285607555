declare module "@mui/material/styles" {
  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
  }

  interface Palette {
    gray: Palette["primary"];
    tertiary: Palette["primary"];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    gray: true;
    tertiary: true;
  }
}

export const colors = {
  primary: {
    main: "#313452",
    light: "#4c5860",
    dark: "#313452",
    contrastText: "#fff",
  },
  secondary: {
    main: "#A238A5",
    light: "#ebb3fa",
    dark: "#420d50",
    contrastText: "#fff",
  },
  tertiary: {
    main: "#245695",
    light: "#b2d6f8",
    dark: "#0d3152",
    contrastText: "#fff",
  },
  gray: {
    main: "#eff0f1",
    light: "#ffffff",
    dark: "rgba(67,68,72,0.42)",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  background: {
    paper: "#fff",
    default: "#fff",
  },
};
