import { PrimaryButton } from "@ppphiiil/mvp-system";
import React, { SetStateAction } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import type { flagCodeType } from "national-flag-icons";
import Flag from "national-flag-icons";
import {
  allLanguagesObject,
  LanguageCodeType,
} from "./language-code/languages";

interface Props {
  language: string;
  setLanguage: (language: SetStateAction<string>) => void;
  variant?: "text" | "outlined" | "contained" | undefined;
}
export const LanguageButton = ({
  setLanguage,
  language,
  ...props
}: Props & React.ComponentProps<typeof PrimaryButton>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LanguageCodeKeys = Object.keys(LanguageCodeType);

  const handleSave = (selectedString: string) => {
    setAnchorEl(null);
    setLanguage(selectedString);
  };

  return (
    <>
      <PrimaryButton
        variant={"text"}
        size={isMdUp ? "medium" : "small"}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleOpen}
        {...props}
      >
        {language !== "" ? t(`languageCode.${language}`).toUpperCase() : "---"}
      </PrimaryButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
      >
        {LanguageCodeKeys.map((code) => {
          const isNotOnline =
            allLanguagesObject[code as keyof typeof allLanguagesObject]
              .online === false;
          return (
            <MenuItem
              key={code}
              value={code}
              onClick={() => {
                handleSave(code);
              }}
              disabled={isNotOnline}
            >
              <Flag
                flagCode={convertFlagCodes(code as flagCodeType)}
                height={16}
                style={{ marginRight: 5 }}
              />
              {isNotOnline
                ? t(`languageCode.${code}`) + " *soon*"
                : t(`languageCode.${code}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const convertFlagCodes = (code: flagCodeType) => {
  if (code === ("en" as flagCodeType)) return "gb";
  if (code === ("uk" as flagCodeType)) return "ua";
  return code;
};
