import { styled, useTheme } from "@mui/material";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChromeLogo } from "../../../assets/svg";
import { isMobileDevice } from "../../../utils/detect-mobile-device/isMobileDevice";

interface Props {
  small?: boolean;
}
export const ChromeButton = ({
  small,
  ...props
}: Props & React.ComponentProps<typeof PrimaryButton>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const ChromeButtonSmallText = styled("div")(({ theme }) => ({
    display: "block",
    width: "100%",
    fontSize: small ? 9 : 14,
    padding: 0,
  }));
  const ChromeButtonNormalText = styled("div")(({ theme }) => ({
    display: "block",
    width: "100%",
    fontSize: small ? 14 : 18,
  }));

  if (isMobileDevice()) return null;

  return (
    <PrimaryButton
      {...props}
      size={small ? "small" : "medium"}
      variant={"outlined"}
      style={{ padding: theme.spacing(1, 3), marginTop: 30 }}
      startIcon={
        <ChromeLogo width={small ? 30 : 40} height={small ? 30 : 40} />
      }
      onClick={() =>
        window.open(process.env.REACT_APP_WEB_CHROME_EXTENSION_URL)
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          padding: 0,
          lineHeight: 1.2,
        }}
      >
        <ChromeButtonSmallText>
          {t("common.googleChromeButton")}
        </ChromeButtonSmallText>
        <ChromeButtonNormalText>{"Google Chrome"}</ChromeButtonNormalText>
      </div>
    </PrimaryButton>
  );
};
