import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

interface StyledButtonProps {
  pill?: boolean;
  bold?: boolean;
}

export const BaseButton = ({
  pill,
  bold,
  ...props
}: LoadingButtonProps & StyledButtonProps) => {
  const theme = useTheme();

  return (
    <LoadingButton
      sx={{
        borderRadius: pill ? 100 : 0,
        fontWeight: bold ? 900 : 400,
        fontSize: props.size === "small" ? 12 : theme.typography.fontSize,
        boxShadow: props.disableElevation ? "none" : theme.shadows[16],
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }}
      {...props}
    />
  );
};
