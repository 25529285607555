import React, { useEffect, useState } from "react";
import "./styles.css";

const SwipeAnimation = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const onAnimationEnd = () => {
      setAnimationComplete(true);
    };

    const swipeAnimationElement = document.getElementById("swipe-animation");
    swipeAnimationElement &&
      swipeAnimationElement.addEventListener("animationend", onAnimationEnd);

    return () => {
      swipeAnimationElement &&
        swipeAnimationElement.removeEventListener(
          "animationend",
          onAnimationEnd
        );
    };
  }, []);

  return (
    <div
      id="swipe-animation"
      style={{ display: animationComplete ? "none" : "" }}
    ></div>
  );
};

export default SwipeAnimation;
