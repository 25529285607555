import { alpha, Chip, styled } from "@mui/material";

export const Section = styled("section")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(15, 0),
  },
}));

export const StyledChipSecondary = styled(Chip)(({ theme }) => ({
  marginBottom: 30,
  fontFamily: "Poppins-ExtraBold",
  letterSpacing: 1,
  color: theme.palette.secondary.main,
  backgroundColor: alpha(theme.palette.secondary.light, 0.5),
}));

export const StyledChipBlue = styled(Chip)(({ theme }) => ({
  marginBottom: 30,
  fontFamily: "Poppins-ExtraBold",
  letterSpacing: 1,
  color: theme.palette.tertiary.main,
  backgroundColor: alpha(theme.palette.tertiary.light, 0.5),
}));

export const StyledInfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 900,
  },
}));

export const InfoCard = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 0),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 0),
  },
}));

export const InfoCardText = styled("div")(({ theme }) => ({
  padding: 0,
  width: "100%",

  paddingBottom: 40,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4, 0),
  },
}));
