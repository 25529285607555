import React, { useState } from "react";
import { LOGIN, REGISTER } from "../../routing/routes";
import { useUser } from "../../hooks/use-user";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/use-logout/useLogout";

import { AddWordButton } from "../../components/AddWordButton/AddWordButton";
import { useTranslation } from "react-i18next";
import UserMenu from "../ui/user-menu/UserMenu";
import { MotherTongueChangeDropdown } from "../../components/MotherTongueChangeDropdown/MotherTongueChangeDropdown";
import { SettingsDialog } from "../../components/SettingsDialog/SettingsDialog";

const ActionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    justifyContent: "start",
    alignItems: "start",
  },
}));

interface Props {
  closeDrawer?: () => void;
}
export const HeaderUserActions = ({ closeDrawer }: Props) => {
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const { user, isLoading: loading } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { mutate: logout, isPending: isLoading } = useLogout();

  if (loading) {
    return null;
  }
  return (
    <div>
      {user ? (
        <ActionContainer>
          <AddWordButton />
          {user.email && (
            <UserMenu
              username={user.email}
              onLogout={() => logout()}
              onSettings={() => setOpenSettings(true)}
            />
          )}
        </ActionContainer>
      ) : (
        <ActionContainer>
          <MotherTongueChangeDropdown />
          <PrimaryButton
            onClick={() => {
              navigate(REGISTER);
              closeDrawer && closeDrawer();
            }}
            size={isMdUp ? "medium" : "small"}
          >
            {t("page.website.header.register")}
          </PrimaryButton>
          {isMdUp && (
            <PrimaryButton
              onClick={() => {
                navigate(LOGIN);
                closeDrawer && closeDrawer();
              }}
              size={isMdUp ? "medium" : "small"}
              variant={"outlined"}
            >
              {t("page.website.header.login")}
            </PrimaryButton>
          )}
        </ActionContainer>
      )}
      <SettingsDialog
        open={openSettings}
        onClose={() => setOpenSettings(false)}
      />
    </div>
  );
};
