import { styled, Typography } from "@mui/material";
import React from "react";

const InfoCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: 16,
  borderRadius: theme.shape.borderRadius,
  height: 130,
  width: "100%",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  cursor: "pointer",
}));
interface Props {
  title: string;
  amount: number;
  onClick: () => void;
}
export const InfoCard = ({ title, amount, onClick }: Props) => {
  return (
    <InfoCardContainer onClick={onClick}>
      <Typography variant={"body1"} style={{ color: "inherit" }}>
        {title}
      </Typography>
      <Typography
        variant={"h2"}
        style={{ color: "inherit", textAlign: "right" }}
      >
        {amount}
      </Typography>
    </InfoCardContainer>
  );
};
