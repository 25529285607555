import { styled } from "@mui/material/styles";

export const SpaceBetweenRowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
}));
