import { Layout } from "../layout/layout";
import { HomePage } from "../pages/HomePage";
import { LearnModePage } from "../pages/LearnModePage";
import { LoginPage } from "../pages/auth/LoginPage";
import { RegisterPage } from "../pages/auth/RegisterPage";
import { useServices } from "../services/service-provider";
import {
  HOME,
  LEARN,
  LOGIN,
  REGISTER,
  ROADMAP,
  VOCABULARY,
  VOCABULARY_SELECTION,
} from "./routes";
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { VocabularyPage } from "../pages/VocabularyPage";
import { VocabularySelectionPage } from "../pages/VocabularySelectionPage";
import { WebsitePage } from "../pages/website/WebsitePage";
import { RoadmapPage } from "../pages/Roadmap/RoadmapPage";

function RequireAuth() {
  const { authService } = useServices();

  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    return authService.onUserChanged(async (user) => {
      setIsLoggedIn(!!user);

      /*if (user) {
        await sendEmailVerification(user as User, {
          url: "https://hello-vocabs.netlify.app/website",
          /!*handleCodeInApp: true*!/
        });
      }*/
    });
  }, [authService]);

  if (isLoggedIn === null) {
    return null;
  } else if (isLoggedIn) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <WebsitePage />
      </Layout>
    );
  }
}
export const AppRouter = () => {
  console.log("approuter");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={REGISTER}
          element={
            <Layout withoutSidebar>
              <RegisterPage />
            </Layout>
          }
        />
        <Route
          path={LOGIN}
          element={
            <Layout>
              <LoginPage />
            </Layout>
          }
        />
        {/*     <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />*/}

        <Route path={"/"} element={<RequireAuth />}>
          <Route path={HOME} element={<HomePage />} />
          <Route path={LEARN} element={<LearnModePage />} />
          <Route path={VOCABULARY} element={<VocabularyPage />} />
          <Route path={ROADMAP} element={<RoadmapPage />} />
          <Route
            path={VOCABULARY_SELECTION}
            element={<VocabularySelectionPage />}
          />

          <Route path={"*"} element={<div>{"Page not found"}</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
