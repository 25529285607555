import {
  Divider,
  List,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../dialog";
import { MotherTongueSetting } from "./MotherTongueSetting";
import { LearnLanguageSetting } from "./LearnLanguageSetting";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const SettingsDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      title={t("components.headerActions.userMenu.settingsDialog.title")}
      isOpen={open}
      onClose={onClose}
      contentPadding={isMdUp ? 3 : 0}
      actions={() => (
        <>
          <PrimaryButton onClick={onClose} variant={"outlined"}>
            {t("components.headerActions.userMenu.settingsDialog.cancel")}
          </PrimaryButton>
        </>
      )}
    >
      <List style={{ padding: 10 }}>
        <ListSubheader>{t("dialog.settings.languageSettings")}</ListSubheader>
        <MotherTongueSetting />
        <Divider />
        <LearnLanguageSetting />
      </List>
    </Dialog>
  );
};
