import AddTranslationDialog from "../components/TranslationDialog/TranslationDialog";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useFetchTranslations } from "../hooks/use-fetch-translations/useFetchTranslations";
import { useAddTranslation } from "../hooks/use-add-translation/useAddTranslation";
import { useDeleteTranslation } from "../hooks/use-delete-translation/useDeleteTranslation";
import { CardView } from "../components/CardView/CardView";
import { InfoCard } from "../components/InfoCard";
import { useNavigate } from "react-router-dom";
import { VOCABULARY } from "../routing/routes";
import { useFetchTranslationsInfo } from "../hooks/use-fetch-translations-infos/useFetchTranslations-info";
import {
  CardContent,
  TranslatedText,
  TranslatedTextContainer,
  TranslationCard,
  VocabularyText,
  VocabularyTextContainer,
} from "./VocabularyPage";
import { Page } from "../layout/layout/Page";
import { useTranslation } from "react-i18next";
import { OnboardingDialog } from "../components/Onboarding/OnboardingDialog";
import { useFetchSetting } from "../hooks/use-fetch-setting/useFetchSetting";

const VocabularyList = styled("ul")({
  listStyleType: "none",
  padding: 0,
  margin: 0,
});

export const HomePage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);
  const { mutate: fetchIsOnboardingFinished } =
    useFetchSetting<"isOnboardingFinished">((isOnboardingFinished) => {
      console.log("isOnboardingFinished", isOnboardingFinished);
      setOnboardingDialogOpen(
        isOnboardingFinished === undefined || !isOnboardingFinished
      );
    });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: fetchedTranslations, isPending } = useFetchTranslations();
  const {
    data: fetchedTranslationsInfos,
    isPending: isPendingFetchedTranslationsInfos,
  } = useFetchTranslationsInfo();
  const { mutate: addTranslation, isPending: isPendingAddTranslation } =
    useAddTranslation();
  const { mutate: deleteTranslation, isPending: isPendingDeleteTranslation } =
    useDeleteTranslation();

  useEffect(() => {
    fetchIsOnboardingFinished("isOnboardingFinished");
  }, []);

  return (
    <Page pageTitle={t("page.home.pageTitle")}>
      {fetchedTranslationsInfos && (
        <CardView
          title={t("page.home.overview.title")}
          content={() => [
            <InfoCard
              title={t("page.home.overview.all")}
              amount={fetchedTranslationsInfos?.all}
              onClick={() => {
                navigate(VOCABULARY);
              }}
            />,
          ]}
        />
      )}

      <CardView
        title={t("page.home.latest.title")}
        content={() => {
          if (fetchedTranslations !== undefined) {
            return fetchedTranslations.map((entry, index) => {
              /*TODO MAKE CARD COMPONENT*/
              return (
                <TranslationCard key={index}>
                  <CardContent>
                    <VocabularyTextContainer>
                      <VocabularyText>{`${entry.word}`}</VocabularyText>
                    </VocabularyTextContainer>
                    <TranslatedTextContainer>
                      <TranslatedText>{`${entry.translation}`}</TranslatedText>
                    </TranslatedTextContainer>
                  </CardContent>
                </TranslationCard>
              );
            });
          } else {
            return [];
          }
        }}
      />

      {/*todo extern button machen für add word*/}
      <AddTranslationDialog
        open={dialogOpen}
        isLoading={isPendingAddTranslation}
        handleClose={() => setDialogOpen(false)}
        onAddWord={(word, wordLang, translation, translationLang) =>
          addTranslation({ word, wordLang, translation, translationLang })
        }
      />
      <OnboardingDialog
        open={onboardingDialogOpen}
        onClose={() => setOnboardingDialogOpen(false)}
      />
    </Page>
  );
};
