import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { Settings } from "../use-fetch-setting/useFetchSetting";

export const useSaveSetting = <T extends keyof Settings>(
  settingName: keyof Settings,
  onSuccess?: () => void
) => {
  const { translationService } = useServices();
  const auth = getAuth();
  const queryClient = useQueryClient();

  return useMutation<void, Error, Settings[T]>({
    mutationFn: async (settingValue) => {
      return translationService.saveSetting(auth, {
        [settingName]: settingValue,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [settingName] });
      onSuccess && onSuccess();
    },
  });
};
