import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useUser } from "../../hooks/use-user";

import { HeaderUserActions } from "./HeaderUserActions";

export const StyledPage = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 1),
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3, 2),
  },
}));

const StyledPageHeader = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  alignItems: "center",
  width: "100%",
  paddingBottom: 30,
}));

interface Props {
  children: React.ReactNode;
  pageTitle?: string;
}
export const Page = ({ children, pageTitle = "" }: Props) => {
  const theme = useTheme();
  const { user } = useUser();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  /*todonext was da los wenn styledpage auch bei children dann verschoben*/
  return (
    <StyledPage>
      {isMdUp ? (
        <>{children}</>
      ) : (
        /*todo header mobile component*/
        <>
          <StyledPageHeader>
            <Typography variant={"h4"}>{pageTitle}</Typography>
            {!isMdUp && user && <HeaderUserActions />}
          </StyledPageHeader>
          {children}
        </>
      )}
    </StyledPage>
  );
};
