import FontLogo from "../../resources/assets/logo/fontlogo2.png";
import { SpaceBetweenRowContainer } from "../ui/containers";
import { Badge, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useUser } from "../../hooks/use-user";
import { HeaderUserActions } from "./HeaderUserActions";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../routing/routes";

const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
}));
const StyledLogo = styled("img")(() => ({
  height: "36px",
}));

export const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));
const ActionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));
export const Header = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <SpaceBetweenRowContainer>
      <LogoContainer onClick={() => navigate(HOME, { replace: true })}>
        <Badge
          badgeContent={"Beta"}
          sx={
            !isMdUp
              ? {
                  "& .MuiBadge-badge": {
                    fontSize: 9,
                    height: 15,
                    minWidth: 15,
                  },
                }
              : {}
          }
          style={{ gap: theme.spacing(1) }}
          anchorOrigin={{
            vertical: isMdUp ? "top" : "top",
            horizontal: "right",
          }}
        >
          {/*  {isMdUp && (
            <StyledLogo
              src={LogoPng}
              style={{ height: isMdUp ? undefined : 20 }}
            />
          )}*/}

          <StyledLogo
            src={FontLogo}
            style={{ height: isMdUp ? undefined : 20 }}
          />
        </Badge>
      </LogoContainer>
      <HeaderUserActions />
    </SpaceBetweenRowContainer>
  );
};
/*
//isMdUp Desktop
Zeig das logo an


//Mobile
zeig nur icon logo an
  wenn user, zeig nix an

*/
