import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { Translation } from "../../types/types";

export const useAddTranslation = () => {
  const { translationService } = useServices();
  const auth = getAuth();
  const queryClient = useQueryClient();
  return useMutation<void, Error, Translation>({
    mutationFn: async ({ word, wordLang, translation, translationLang }) => {
      return translationService.saveTranslation(
        auth,
        word,
        wordLang,
        translation,
        translationLang
      );
    },
    onSuccess: () => {
      console.log("useAddTranslation success");
      queryClient.invalidateQueries({ queryKey: ["translations"] });
      queryClient.invalidateQueries({ queryKey: ["infos"] });
    },
  });
};
