import { Services } from "../services/service-provider";
import { authService } from "./service-instances/firebaseService";
import { translationService } from "./service-instances/translationService";
import { serviceWorkerService } from "./service-instances/serviceWorkerService";

export const services: Services = {
  authService,
  translationService,
  serviceWorkerService,
};
