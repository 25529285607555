import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSaveSetting } from "../../hooks/use-save-setting/useSaveSetting";
import { useUser } from "../../hooks/use-user";
import { useFetchSetting } from "../../hooks/use-fetch-setting/useFetchSetting";
import { LanguageButton } from "../shared/LanguageButton/LanguageButton";

interface Props {}
export const MotherTongueChangeDropdown = ({}: Props) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const { mutate: saveMotherTongue } = useSaveSetting("motherTongue");
  const { user } = useUser();

  const { mutate: fetchMontherTongue } = useFetchSetting<"motherTongue">(
    (motherTongue) => {
      motherTongue && setLanguage(motherTongue);
    }
  );
  useEffect(() => {
    fetchMontherTongue("motherTongue");
  }, []);

  useEffect(() => {
    if (user) {
      saveMotherTongue(language);
    }
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div>
      <LanguageButton language={language} setLanguage={setLanguage} />
    </div>
  );
};
