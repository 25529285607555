import {
  Dialog,
  DialogActions,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import React, { useEffect, useState } from "react";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useTranslation } from "react-i18next";
import { DirectionChanger } from "../AddWordButton/DirectionChanger";
import { useLanguage } from "../../hooks/use-language/useLanguage";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { TextToSpeachButton } from "../PlaySoundButton/TextToSpeachButton";
import { useFetchSetting } from "../../hooks/use-fetch-setting/useFetchSetting";
import { LanguageCodes } from "../shared/LanguageButton/language-code/languages";

export const StyledDialogActionContainer = styled("div")(({}) => ({
  display: "flex",
  marginBottom: 40,
  marginTop: 10,
  gap: 10,
}));

export const StyledDialogActions = styled(DialogActions)(({}) => ({
  marginTop: 20,
}));

export const StyledDialogTypography = styled(Typography)(({}) => ({
  marginBottom: 30,
  variant: "h2",
}));
export const StyledDialogContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: 40,
  borderRadius: 30,
  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    padding: 20,
  },
}));

interface TranslationDialogProps {
  open: boolean;
  handleClose: () => void;
  isLoading: boolean;
  onAddWord: (
    word: string,
    wordLang: LanguageCodes,
    translation: string,
    translationLang: LanguageCodes
  ) => void; // Callback to add word and translation
}

const TranslationDialog: React.FC<TranslationDialogProps> = ({
  open,
  handleClose,
  isLoading,
  onAddWord,
}) => {
  const [translation, setTranslation] = useState(""); // State to store the edited translation
  const [word, setWord] = useState("");
  const auth = getAuth();
  const { language } = useLanguage();

  const [sourceLanguage, setSourceLanguage] = useState<string>("");
  const [targetLanguage, setTargetLanguage] = useState<string>(language);
  const [isTranslationDirectionChanged, setIsTranslationDirectionChanged] =
    useState<boolean>(false);
  const { mutate: fetchLearnLanguage } = useFetchSetting<"learnLanguage">(
    (learnLanguage) => {
      learnLanguage && setSourceLanguage(learnLanguage);
    }
  );
  /*todo we need a state management*/
  const { mutate: fetchMotherTongue } = useFetchSetting<"motherTongue">(
    (motherTongue) => {
      motherTongue && setTargetLanguage(motherTongue);
    }
  );

  const { translationService } = useServices();

  /*todo wired*/
  useEffect(() => {
    fetchMotherTongue("motherTongue");
    fetchLearnLanguage("learnLanguage");
  }, [open]);
  /*
  useEffect(() => {
    setTargetLanguage(language);
  }, [language, open]);*/

  useEffect(() => {
    setTranslation("");
  }, [isTranslationDirectionChanged]);

  const translateWord = async () => {
    if (word.trim() !== "") {
      const translation = await translationService.getTranslationFromGoogle(
        auth,
        word,
        {
          from: sourceLanguage,
          to: targetLanguage,
        }
      );
      if (translation) {
        setTranslation(translation);
      }
    }
  };

  const handleAddWord = () => {
    //todo onsuccess dann anzeigen
    //todo delete and make fetch
    onAddWord(
      word,
      sourceLanguage as LanguageCodes,
      translation,
      targetLanguage as LanguageCodes
    ); // Callback to add word and translation
    setTranslation("");
    setWord("");
    handleClose();
  };

  const { t } = useTranslation();
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <StyledDialogContainer>
        <StyledDialogTypography variant={"h2"}>
          {t("components.addTranslationButton.dialog.title")}
        </StyledDialogTypography>

        <StyledDialogActionContainer>
          <DirectionChanger
            sourceLanguage={sourceLanguage}
            targetLanguage={targetLanguage}
            onSourceLanguageChange={setSourceLanguage}
            onTargetLanguageChange={setTargetLanguage}
            onTranslationDirectionChanged={setIsTranslationDirectionChanged}
          />
        </StyledDialogActionContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 20,
            gap: 10,
          }}
        >
          <>
            <TextField
              label={t(`languageCode.${sourceLanguage}`)}
              fullWidth
              autoFocus
              focused
              value={word}
              onChange={(e) => setWord(e.target.value)}
            />
            <PrimaryButton
              variant={"text"}
              onClick={translateWord}
              startIcon={<GTranslateIcon />}
            >
              {t("components.addTranslationButton.dialog.translateButton")}
            </PrimaryButton>
            <div style={{ display: "flex", flexDirection: "row", flexGrow: 0 }}>
              <TextField
                label={t(`languageCode.${targetLanguage}`)}
                fullWidth
                focused
                value={translation}
                onChange={(e) => setTranslation(e.target.value)}
              />

              <TextToSpeachButton
                text={translation}
                language={targetLanguage}
              />
            </div>
          </>
        </div>

        <StyledDialogActions>
          <PrimaryButton
            onClick={handleClose}
            variant={"outlined"}
            color="primary"
          >
            {t("components.addTranslationButton.dialog.cancelButton")}
          </PrimaryButton>
          <PrimaryButton
            onClick={handleAddWord}
            loading={isLoading}
            color="primary"
            variant={"contained"}
          >
            {t("components.addTranslationButton.dialog.addButton")}
          </PrimaryButton>
        </StyledDialogActions>
      </StyledDialogContainer>
    </Dialog>
  );
};

export default TranslationDialog;
