import { Alert } from "@mui/material";
import { AppError } from "@ppphiiil/mvp-system";
import { MVPErrorType } from "@ppphiiil/mvp-system/dist/services/app-error/error-types";
import { useTranslation } from "react-i18next";
import { ErrorTypes } from "../services/app-error/error-types";

interface Props {
  error: AppError<MVPErrorType> | null;
}

export const ErrorMessage = ({ error }: Props) => {
  const { t } = useTranslation();
  return error ? (
    <div>
      <Alert severity="error">{t(`common.errors.${error.errorType}`)}</Alert>
    </div>
  ) : null;
};

export const convertFirebaseErrorToAppError = (error: any): any => {
  switch (error.code) {
    case "auth/user-not-found":
      return new AppError<any>(ErrorTypes.USER_NOT_FOUND);
    case "auth/wrong-password":
      return new AppError(ErrorTypes.INVALID_CREDENTIALS);
    case "auth/email-already-exists":
      return new AppError(ErrorTypes.DUPLICATE_EMAIL);
    case "auth/email-already-in-use":
      return new AppError(ErrorTypes.DUPLICATE_EMAIL);
    case "auth/network-request-failed":
      return new AppError(ErrorTypes.NETWORK);
    default:
      return new AppError(ErrorTypes.UNKNOWN);
  }
};
