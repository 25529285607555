import { useQuery } from "@tanstack/react-query";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { useUser } from "../use-user";
import {
  LearnStatus,
  TranslationInfos,
} from "../../services/translations/translation-service";

export const useFetchTranslationsInfo = (filter?: LearnStatus) => {
  const { translationService } = useServices();
  const { user } = useUser();
  const auth = getAuth();
  return useQuery<TranslationInfos, Error>({
    queryKey: ["infos"],
    queryFn: async () => {
      return await translationService.fetchTranslationsInfos(auth);
    },
  });
};
