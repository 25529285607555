import { HOME } from "../../routing/routes";
import { useServices } from "../../services/service-provider";
import { AuthPage, Login } from "@ppphiiil/mvp-system";
import React from "react";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { authService } = useServices();
  const navigate = useNavigate();
  return (
    <AuthPage>
      <Login
        onSubmit={async (formValues) => {
          //todo mit backend nicht gegen firebase
          try {
            const user = await authService.login({
              email: formValues.email,
              password: formValues.password,
            });
            if ("accessToken" in user) {
              const accessToken = user.accessToken as string; // Type guard
              //await localStorage.setItem("token", accessToken);
              navigate(HOME, { replace: true });
            }
          } catch (error) {
            console.log("Error in login:", error);
          }
        }}
      />
    </AuthPage>
  );
};
