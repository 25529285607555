import { getAuth } from "@firebase/auth";
import { AuthService } from "@ppphiiil/mvp-system";
import { initializeApp } from "@firebase/app";

console.log(
  "init firebase",
  `./firebase-config.${process.env.REACT_APP_NODE_ENV}.json`
);
const firebaseConfig = require(`./firebase-config.${process.env.REACT_APP_NODE_ENV}.json`);
const test = initializeApp(firebaseConfig);
const auth = getAuth(test);
auth.languageCode = navigator.languages[0];
export const authService = new AuthService(auth);
