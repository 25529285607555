import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import { styled, Typography } from "@mui/material";
import { ChromeButton } from "../../layout/ui/buttons/ChromeButton";
import { AddWordButton } from "../AddWordButton/AddWordButton";
import { useTranslation } from "react-i18next";
import { LearnLanguageChangeDropdown } from "../LearnLanguageChangeDropdown/LearnLanguageChangeDropdown";
import { MotherTongueChangeDropdown } from "../MotherTongueChangeDropdown/MotherTongueChangeDropdown";
import { useInstallationPromptEvent } from "../../utils/installation-provider";
import { useSaveSetting } from "../../hooks/use-save-setting/useSaveSetting";
import { IOsAddToHomescreenInstructionModal } from "../../layout/layout/IOsAddToHomescreenInstructionModal";

const ActionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}));

const SwiperPage = styled("div")(({ theme }) => ({
  padding: theme.spacing(6),
}));

const Headline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const SubHeadline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

interface Props {
  onClose?: () => void;
}

export default function Onboarding({ onClose }: Props) {
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const pwaInstallEvent = useInstallationPromptEvent();
  const [
    isIOsAddToHomescreenInstructionModalOpen,
    setIsIOsAddToHomescreenInstructionModalOpen,
  ] = useState<boolean>(false);
  const { mutate: saveIsOnboardingFinished } = useSaveSetting(
    "isOnboardingFinished"
  );

  const prevHandler = () => {
    if (swiperRef) {
      if (swiperRef.isEnd) {
      }
      swiperRef.slidePrev();
    }
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  return (
    <>
      <Swiper
        style={{ marginTop: 10 }}
        pagination={{
          type: "progressbar",
        }}
        onSwiper={(swiper: SwiperClass) => {
          swiper.on("reachEnd", function () {
            setReachedEnd(true);
          });

          setSwiperRef(swiper);
        }}
        navigation={false}
        modules={[Pagination, Navigation]}
      >
        <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideLearnLanguage.headline")}
            </Headline>
            <LearnLanguageChangeDropdown onIsLoading={setIsLoading} />
          </SwiperPage>
        </SwiperSlide>
        <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideMotherTongue.headline")}
            </Headline>
            <MotherTongueChangeDropdown />
          </SwiperPage>
        </SwiperSlide>
        <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideSaveFirstVocable.headline")}
            </Headline>
            <div
              style={{
                padding: 30,
                textAlign: "center",
              }}
            >
              <AddWordButton />
            </div>
          </SwiperPage>
        </SwiperSlide>
        <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideChromeExtension.headline")}
            </Headline>
            <Typography variant={"body2"}>
              {t("onboarding.slideChromeExtension.subHeadline")}
            </Typography>
            <ChromeButton />
          </SwiperPage>
        </SwiperSlide>
        {/*  <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideTryOut.headline")}
            </Headline>
            <Typography variant={"body2"}>
              {t("onboarding.slideTryOut.subHeadline")}
            </Typography>
          </SwiperPage>
        </SwiperSlide>*/}
        <SwiperSlide>
          <SwiperPage>
            <Headline variant={"h2"}>
              {t("onboarding.slideSaveApp.headline")}
            </Headline>
            <SubHeadline variant={"body2"}>
              {t("onboarding.slideSaveApp.subHeadline")}
            </SubHeadline>

            <PrimaryButton
              startIcon={<AddToHomeScreenIcon />}
              onClick={() => {
                pwaInstallEvent.value !== null
                  ? pwaInstallEvent.value.prompt() /*for android*/
                  : setIsIOsAddToHomescreenInstructionModalOpen(true);
              }}
            >
              {t("components.sideMenu.addToHomescreen")}
            </PrimaryButton>
          </SwiperPage>
        </SwiperSlide>
      </Swiper>
      {/*ACTIONS*/}
      <ActionContainer>
        <PrimaryButton
          loading={isLoading}
          variant={"outlined"}
          onClick={prevHandler}
        >
          {t("onboarding.btnPrev")}
        </PrimaryButton>
        {reachedEnd ? (
          <PrimaryButton
            onClick={() => {
              saveIsOnboardingFinished(true);
              onClose && onClose();
            }}
          >
            {t("onboarding.btnClose")}
          </PrimaryButton>
        ) : (
          <PrimaryButton loading={isLoading} onClick={nextHandler}>
            {t("onboarding.btnNext")}
          </PrimaryButton>
        )}
      </ActionContainer>
      <IOsAddToHomescreenInstructionModal
        onOpen={isIOsAddToHomescreenInstructionModalOpen}
        onClose={() => {
          setIsIOsAddToHomescreenInstructionModalOpen(false);
        }}
      />
    </>
  );
}
