import { ListItem, ListItemText } from "@mui/material";
import React from "react";

interface Props {
  name: string;
  children: React.ReactNode;
}
export const SettingItem = ({ name, children }: Props) => {
  return (
    <ListItem>
      <ListItemText primary={name} />
      {children}
    </ListItem>
  );
};
