export enum AuthErrorTypes {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_TOKEN = "INVALID_TOKEN",
  DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  INVALID_REQUEST = "INVALID_REQUEST",
  CREATE_USER_FAILED = "CREATE_USER_FAILED",
  UPDATE_USER_FAILED = "UPDATE_USER_FAILED",
  FAILED_TO_DELETE = "FAILED_TO_DELETE",
  USER_EXISTS = "USER_EXISTS",
}
