import { useServices } from "../../services/service-provider";
import { User } from "@firebase/auth";
import { AuthUser } from "@ppphiiil/mvp-system/dist/services/auth/auth-service";
import React, { useState } from "react";

export function useUser() {
  const { authService } = useServices();
  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = React.useState<AuthUser | User | null>(null);
  React.useEffect(() => {
    setIsLoading(false);
    return authService.onUserChanged(setUser);
  }, [authService]);

  return { user, isLoading }; /*todo not ok*/
}
