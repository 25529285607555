import React from "react";
import Onboarding from "./Onboarding";
import { Dialog } from "../dialog";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const OnboardingDialog = ({ open, onClose }: Props) => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const prevSlide = () => {};
  return (
    <Dialog isOpen={open} onClose={() => {}} contentPadding={3}>
      <Onboarding onClose={onClose} />
    </Dialog>
  );
};
