import { AuthErrorTypes } from "./auth-errors";

export enum DefaultErrorTypes {
  UNKNOWN = "UNKNOWN",
  NETWORK = "NETWORK",
  DEVELOPER = "DEVELOPER",
}

//here combine all ErrorTypes of the components
export const ErrorTypes = {
  ...AuthErrorTypes,
  ...DefaultErrorTypes,
} as const;

export type ErrorType = AuthErrorTypes | DefaultErrorTypes;
