import React, { useState } from "react";

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  StyledDialogActionContainer,
  StyledDialogActions,
  StyledDialogContainer,
  StyledDialogTypography,
} from "../TranslationDialog/TranslationDialog";
import { Dialog } from "@mui/material";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import { useTranslation } from "react-i18next";
import { SettingsLearnMode } from "../VocabularyCard";
import { ChipSelection } from "../ChipSelection/ChipSelection";
import { Direction } from "../../types/types";

interface Props {
  onChangeSettings: (settings: SettingsLearnMode) => void;
  translationDirections: Direction[];
}
export const LearnModeSettingsButton = ({
  onChangeSettings,
  translationDirections,
}: Props) => {
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const [direction, setDirection] = useState<Direction[]>([
    translationDirections[0],
  ]);

  const { t } = useTranslation();
  return (
    <>
      <PrimaryButton
        variant={"outlined"}
        size={"small"}
        startIcon={<SettingsRoundedIcon />}
        onClick={() => setOpenDialog(true)}
      >
        {t("page.learnMode.settingsButton")}
      </PrimaryButton>
      <Dialog fullWidth open={openDialog} onClose={() => {}}>
        <StyledDialogContainer>
          <StyledDialogTypography variant={"h2"}>
            {t("page.learnMode.settings.headline")}
          </StyledDialogTypography>
          <StyledDialogActionContainer>
            <ChipSelection<Direction>
              filters={translationDirections}
              selectedFilters={direction}
              onFilterClick={(selectedFilters) => {
                /*todo double state*/
                setDirection([selectedFilters[0]]);
                onChangeSettings({ direction: selectedFilters[0] });
              }}
              multipleSelection={false}
              headline={t("page.learnMode.settings.translationDirection")}
            />
          </StyledDialogActionContainer>

          <StyledDialogActions>
            <PrimaryButton
              onClick={() => setOpenDialog(false)}
              variant={"outlined"}
              color="primary"
            >
              {t("components.button.cancel")}
            </PrimaryButton>
            <PrimaryButton
              /*todo save settings*/
              onClick={() => setOpenDialog(false)}
              color="primary"
              variant={"contained"}
            >
              {t("components.button.save")}
            </PrimaryButton>
          </StyledDialogActions>
        </StyledDialogContainer>
      </Dialog>
    </>
  );
};
