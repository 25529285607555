import { getAuth } from "@firebase/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useServices } from "../../services/service-provider";

export const useDeleteTranslation = () => {
  const { translationService } = useServices();
  const auth = getAuth();
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: async (idToDelete) => {
      return await translationService.deleteTranslations(auth, idToDelete);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["translations"] });
    },
    onError: () => {
      console.log("error while deleting");
    },
  });
};
