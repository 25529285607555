import { styled } from "@mui/material/styles";

export const RoundedContainer = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  width: "100%",
  boxShadow: "none",
  [theme.breakpoints.up("md")]: {
    height: "100%",
  },
}));
