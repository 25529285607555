import React from "react";
import { customizedBlackTheme } from "./theme/theme";

import { AppRouter } from "../routing";
import {
  MVPI18nProvider,
  mvpInitI18n,
  ThemeProvider,
} from "@ppphiiil/mvp-system";
import { de, en, es, fr, it, pt, sk } from "./translations";
import { useLanguage } from "../hooks/use-language/useLanguage";
import { InstallationProvider } from "../utils/installation-provider";
import { useBeforeInstallPromptHandler } from "../hooks/useBeforeInstallPrompzHandler";
import { AppUpdateDialog } from "../components/app-update-dialog";
import { languageCodeI18n } from "../components/shared/LanguageButton/language-code";

export const App = () => {
  console.log("app");
  const { language } = useLanguage();
  const value = useBeforeInstallPromptHandler();

  return (
    <InstallationProvider pwaInstallEvent={{ value }}>
      <MVPI18nProvider
        i18n={mvpInitI18n(
          {
            de: {
              translation: {
                ...de,
                ...languageCodeI18n.de,
              },
            },
            en: {
              translation: {
                ...en,
                ...languageCodeI18n.en,
              },
            },
            es: {
              translation: {
                ...es,
                ...languageCodeI18n.es,
              },
            },
            it: {
              translation: {
                ...it,
                ...languageCodeI18n.it,
              },
            },
            pt: {
              translation: {
                ...pt,
                ...languageCodeI18n.pt,
              },
            },
            fr: {
              translation: {
                ...fr,
                ...languageCodeI18n.fr,
              },
            },
            sk: {
              translation: {
                ...sk,
                ...languageCodeI18n.sk,
              },
            },
          },
          language ?? navigator.languages[0] ?? "en"
        )}
      >
        <ThemeProvider theme={customizedBlackTheme}>
          <AppRouter />
          <AppUpdateDialog />
        </ThemeProvider>
      </MVPI18nProvider>
    </InstallationProvider>
  );
};
