import {styled} from "@mui/material/styles";



export const DrawerSideMenuContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginRight: 0,
  width: "100%",
  maxWidth: "350px",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("md")]: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    maxWidth: "none",
    width: "200px",
    height: "100%",
  },
}));
