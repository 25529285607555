import React from "react";
import { IconButton, styled, useTheme } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { LanguageButton } from "../shared/LanguageButton/LanguageButton";

const TranslationDirectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 10,
  width: "100%",
  justifyContent: "flex-start",
  alignContent: "center",
}));

interface Props {
  sourceLanguage: string;
  targetLanguage: string;
  onSourceLanguageChange: (
    sourceLanguage: React.SetStateAction<string>
  ) => void;
  onTargetLanguageChange: (
    targetLanguage: React.SetStateAction<string>
  ) => void;
  onTranslationDirectionChanged: (
    isTranslationDirectionChanged: React.SetStateAction<boolean>
  ) => void;
}

export const DirectionChanger = ({
  onTranslationDirectionChanged,
  onSourceLanguageChange,
  onTargetLanguageChange,
  sourceLanguage,
  targetLanguage,
}: Props) => {
  const theme = useTheme();
  /*todo warum hier als einziger fetche alles andere außen?*/

  //todo change string to LanguageCodes

  return (
    <TranslationDirectionContainer>
      <LanguageButton
        language={sourceLanguage}
        setLanguage={onSourceLanguageChange}
        variant={"contained"}
        color={"gray"}
        fullWidth
      />
      <IconButton
        color={"primary"}
        onClick={() => {
          //change source language to targetlanguage and reverse
          onSourceLanguageChange(targetLanguage);
          onTargetLanguageChange(sourceLanguage);
          onTranslationDirectionChanged((prev) => !prev);
        }}
      >
        <SwapHorizIcon fontSize="small" />
      </IconButton>
      <LanguageButton
        language={targetLanguage}
        setLanguage={onTargetLanguageChange}
        variant={"contained"}
        color={"gray"}
        fullWidth
      />
    </TranslationDirectionContainer>
  );
};
