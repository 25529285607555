import React, { useEffect, useState } from "react";
import { useSaveSetting } from "../../hooks/use-save-setting/useSaveSetting";
import { useUser } from "../../hooks/use-user";
import { useFetchSetting } from "../../hooks/use-fetch-setting/useFetchSetting";
import { LanguageButton } from "../shared/LanguageButton/LanguageButton";

interface Props {
  onIsLoading?: (isLoading: boolean) => void;
}
export const LearnLanguageChangeDropdown = ({ onIsLoading }: Props) => {
  const [language, setLanguage] = useState<string>("");
  const { mutate: fetchLearnLanguage } = useFetchSetting<"learnLanguage">(
    (learnLanguage) => {
      learnLanguage && setLanguage(learnLanguage);
      onIsLoading && onIsLoading(false);
    }
  );
  useEffect(() => {
    fetchLearnLanguage("learnLanguage");
    onIsLoading && onIsLoading(true);
  }, []);

  const { mutate: saveLearnLanguage } = useSaveSetting("learnLanguage");
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      saveLearnLanguage(language);
    }
  }, [language]);
  return <LanguageButton language={language} setLanguage={setLanguage} />;
};
