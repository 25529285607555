import { styled } from "@mui/material/styles";

export const RoundedBorderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: 0, //todo schaun wo es alles nicht mehr rund ist
  border: `1.5px solid ${theme.palette.grey["200"]}`,
  [theme.breakpoints.up("md")]: {
    border: `1.5px solid ${theme.palette.grey["200"]}`,
    backgroundColor: "transparent",
    padding: theme.spacing(4),
  },
}));
