import VocabularyCard from "../components/VocabularyCard";
import React from "react";
import { useFetchTranslations } from "../hooks/use-fetch-translations/useFetchTranslations";
import { Page } from "../layout/layout/Page";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { CheckIfConditionOrLoading } from "../components/CheckIfConditionOrLoading.tsx/CheckIfConditionsOrLoading";

interface Props {}
export const LearnModePage = ({}: Props) => {
  const { t } = useTranslation();
  const { data: fetchedTranslations } = useFetchTranslations();

  return (
    <Page pageTitle={t("page.learnMode.pageTitle")}>
      <CheckIfConditionOrLoading
        isLoading={!!fetchedTranslations}
        condition={fetchedTranslations ? fetchedTranslations.length > 0 : false}
        showElementIfCondition={
          <Typography>{t("page.learnMode.noCards")}</Typography>
        }
      >
        <VocabularyCard translations={fetchedTranslations ?? []} />
        {/* todo new <CardDeck
              translations={fetchedTranslations}
              cards={fetchedTranslations.map((card) => {
                return (
                  <Flashcard
                    key={card.id}
                    word={card.word}
                    translation={card.translation}
                    onSwipeLeft={() => {}}
                    onSwipeRight={() => {}}
                  />
                );
              })}
            />*/}
      </CheckIfConditionOrLoading>
      {/*      {fetchedTranslations ? (
        fetchedTranslations.length > 0 ? (
          <>
            <VocabularyCard translations={fetchedTranslations} />
            todo new
            <CardDeck
              translations={fetchedTranslations}
              cards={fetchedTranslations.map((card) => {
                return (
                  <Flashcard
                    key={card.id}
                    word={card.word}
                    translation={card.translation}
                    onSwipeLeft={() => {}}
                    onSwipeRight={() => {}}
                  />
                );
              })}
            />
          </>
        ) : (
          <Typography>{t("page.learnMode.noCards")}</Typography>
        )
      ) : (
        <CircularProgress />
      )}*/}
    </Page>
  );
};
