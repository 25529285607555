export interface ServiceWorkerWrapper {
  update: () => void;
}

export class ServiceWorkerWrapperImpl implements ServiceWorkerWrapper {
  constructor(private readonly registration: ServiceWorkerRegistration) {}

  update(): void {
    const registrationWaiting = this.registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if ((e.target as ServiceWorker | null)?.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
}
