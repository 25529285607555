import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";

interface UserMenuProps {
  username: string;
  onLogout: () => void;
  onSettings: () => void;
}

const UserAvatar = styled(Avatar)`
  cursor: pointer;
`;

const UserMenu: React.FC<UserMenuProps> = ({
  username,
  onLogout,
  onSettings,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout(); // Call your logout function here
    handleClose();
  };
  const handleSettings = () => {
    onSettings();
    handleClose();
  };

  return (
    <>
      {/*    <UserAvatar
        sx={{ height: 30, width: 30, fontSize: "1rem" }}
        onClick={handleMenuClick}
      >
        {username.charAt(0).toUpperCase()}
      </UserAvatar>*/}
      <IconButton
        onClick={handleMenuClick}
        sx={{ color: theme.palette.gray.dark, padding: 0 }}
        size={"large"}
      >
        <AccountCircleIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList style={{ width: 320, maxWidth: "100%" }}>
          <MenuItem onClick={handleSettings}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("components.headerActions.userMenu.settingsDialog.title")}
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("components.headerActions.userMenu.logout")}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
export default UserMenu;
