import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

// import required modules
import { Mousewheel, Navigation, Pagination, Scrollbar } from "swiper/modules";

interface Props {
  title: string;
  content: () => React.JSX.Element[];
}
export const CardView = ({ title, content }: Props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div style={{ paddingBottom: 30 }}>
      <Typography style={{ paddingBottom: 20 }} variant={"h4"}>
        {title}
      </Typography>

      <Swiper
        slidesPerView={isMdUp ? 3.1 : 1.1}
        cssMode={true}
        grabCursor={true}
        scrollbar={true}
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        navigation={false}
        spaceBetween={10}
        mousewheel={true}
        modules={[Pagination, Mousewheel, Scrollbar, Navigation]}
        className="mySwiper"
      >
        {content().map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
