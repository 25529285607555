import { BaseButton } from "./BaseButton";
import { styled, useTheme } from "@mui/material";
import React from "react";
import { NavLink, To } from "react-router-dom";

interface MenuButtonProps {
  to?: To;
  bold?: boolean;
  startIcon: React.ReactElement;
  end?: boolean;
}

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  justifyContent: "flex-start",
  marginBottom: theme.spacing(1.5),

  [`& .start-icon`]: {
    color: theme.palette.gray.dark,
  },
}));

export const MenuButton = ({
  startIcon,
  bold = false,
  to,
  end,
  ...props
}: Omit<React.ComponentProps<typeof BaseButton>, "startIcon"> &
  MenuButtonProps) => {
  const theme = useTheme();
  const createStyledBaseButton = (isActive: boolean) => (
    <StyledBaseButton
      startIcon={React.cloneElement(startIcon, {
        className: !isActive ? "start-icon" : undefined,
      })}
      variant={isActive ? "contained" : "text"}
      sx={{ color: isActive ? "primary" : theme.palette.gray.dark }}
      disableElevation={true}
      fullWidth
      bold={bold}
      {...props}
    />
  );

  return (
    <>
      {to ? (
        <NavLink
          to={to}
          end={end}
          style={{ textDecoration: "none" }}
          children={({ isActive }) => createStyledBaseButton(isActive)}
        />
      ) : (
        createStyledBaseButton(false)
      )}
    </>
  );
};
