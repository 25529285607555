import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import {
  ServiceWorkerWrapper,
  ServiceWorkerWrapperImpl,
} from "./service-worker-wrapper";

type Unregister = () => void;
type ServiceWorkerListener = (serviceWorker: ServiceWorkerWrapper) => void;

export class ServiceWorkerService {
  private listeners: ServiceWorkerListener[] = [];

  init() {
    console.log("init serviceworker");
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        const wrapper = new ServiceWorkerWrapperImpl(registration);
        this.listeners.map((listener) => listener(wrapper));
      },
    });
  }

  registerListener(listener: ServiceWorkerListener): Unregister {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((it) => it !== listener);
    };
  }
}
