import { generatePath } from "react-router-dom";

export const HOME = "/";
export const REGISTER = "/register";
export const LOGIN = "/login";
export const LEARN = "/learn";
export const VOCABULARY = "/vocabulary";
export const ROADMAP = "/roadmap";

export const VOCABULARY_SELECTION = "/vocabulary-selection/:filter";

export function generateVocabularySelectionPath(filter: string) {
  return generatePath(VOCABULARY_SELECTION, { filter });
}
