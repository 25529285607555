import { IconButton, styled } from "@mui/material";
import React from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useServices } from "../../services/service-provider";
import { getAuth } from "@firebase/auth";
import { useTextToSpeach } from "../../hooks/use-text-to-speach/useTextToSpeach";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  touchAction: "manipulation",
}));
interface Props {
  text: string;
  language: string;
}
export const TextToSpeachButton = ({ text, language }: Props) => {
  const { translationService } = useServices();
  const auth = getAuth();
  const { mutate: play } = useTextToSpeach();

  return (
    <StyledIconButton
      onClick={(e) => {
        play({ text, language });
        e.stopPropagation();
      }}
    >
      <VolumeUpIcon />
    </StyledIconButton>
  );
};
