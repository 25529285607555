import { useEffect, useState } from "react";

export interface InstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export type BeforeInstallPromptEvent = { value: InstallPromptEvent };

export const useBeforeInstallPromptHandler =
  (): BeforeInstallPromptEvent | null => {
    //const queryClient = useQueryClient();
    const [pwaInstallEvent, setPwaInstallEvent] =
      useState<BeforeInstallPromptEvent | null>(null);

    const beforeInstallPromptHandler = (e: Event) => {
      const event = { value: e } as BeforeInstallPromptEvent;
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      //e.preventDefault();
      // Save to trigger it later.
      setPwaInstallEvent(event);
    };

    useEffect(() => {
      window.addEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          beforeInstallPromptHandler
        );
      };
    }, []);

    return pwaInstallEvent;
  };
