import { useEffect, useState } from "react";
import { useServices } from "../../services/service-provider";
import { useFetchSetting } from "../use-fetch-setting/useFetchSetting";
import { useUser } from "../use-user";

export const useLanguage = () => {
  const [language, setLanguage] = useState<string>("en");
  const { authService } = useServices();
  const {
    mutate: fetchSetting,
    error,
    isPending,
  } = useFetchSetting<"motherTongue">((motherTongue) => {
    console.log("useFetchSetting on success motherTongue:  ", motherTongue);
    setLanguage(motherTongue);
  });

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      fetchSetting("motherTongue");
    }
  }, [user]);

  return { language, isLoading: isPending };
};
