import { useUser } from "../hooks/use-user";
import { useServices } from "../services/service-provider";

import { alpha, styled } from "@mui/material/styles";
import { PrimaryButton } from "@ppphiiil/mvp-system";
import React from "react";
import { Typography } from "@mui/material";
import { useFetchTranslations } from "../hooks/use-fetch-translations/useFetchTranslations";
import { useDeleteTranslation } from "../hooks/use-delete-translation/useDeleteTranslation";
import { Page } from "../layout/layout/Page";
import { useTranslation } from "react-i18next";
import { CheckIfConditionOrLoading } from "../components/CheckIfConditionOrLoading.tsx/CheckIfConditionsOrLoading";

const VocabularyList = styled("ul")({
  listStyleType: "none",
  padding: 0,
  margin: 0,
});

export const TranslationCard = styled("li")(() => ({
  backgroundColor: "white",
  borderRadius: "15px",
  width: "100%",
  height: "100%",
  border: "1px solid",
  borderColor: "#dedede",
  overflow: "hidden",
  marginBottom: "10px",
  fontSize: "1.3em",
}));

export const VocabularyTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "bold",
  backgroundColor: alpha(theme.palette.primary.light, 0.06),
  overflow: "hidden",
  height: "100%",
  width: "100%",
  padding: "20px",
}));

export const VocabularyText = styled(Typography)(({}) => ({
  fontWeight: "bold",
  height: "100%",

  maxHeight: "3em" /* Adjust to fit approximately two rows of text */,
  overflow: "hidden",
  textOverflow: "ellipsis" /* Optional: Add ellipsis for overflowed text */,
  whiteSpace: "normal",
}));

export const TranslatedTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "bold",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  justifyContent: "space-between",
  width: "100%",
  padding: "20px",
}));

export const TranslatedText = styled(Typography)({
  height: "100%",
  maxHeight: "3em" /* Adjust to fit approximately two rows of text */,
  overflow: "hidden",
  textOverflow: "ellipsis" /* Optional: Add ellipsis for overflowed text */,
  whiteSpace: "normal",
});
export const CardContent = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
}));

export const VocabularyPage = () => {
  const { user } = useUser();

  const { authService } = useServices();
  const { t } = useTranslation();
  const { data: fetchedTranslations, isPending } = useFetchTranslations();

  const { mutate: deleteTranslation, isPending: isPendingDeleteTranslation } =
    useDeleteTranslation();

  return (
    <Page pageTitle={t("page.vocabulary.pageTitle")}>
      <CheckIfConditionOrLoading
        isLoading={!!fetchedTranslations}
        condition={fetchedTranslations ? fetchedTranslations.length > 0 : false}
        showElementIfCondition={
          <Typography>{t("page.learnMode.noCards")}</Typography>
        }
      >
        <VocabularyList>
          {fetchedTranslations !== undefined &&
            fetchedTranslations.map((entry, index) => (
              <TranslationCard key={index}>
                <CardContent>
                  <VocabularyTextContainer>
                    <VocabularyText>{`${entry.word}`}</VocabularyText>
                  </VocabularyTextContainer>
                  <TranslatedTextContainer>
                    <TranslatedText>{`${entry.translation}`}</TranslatedText>
                    <PrimaryButton
                      variant="outlined"
                      loading={isPendingDeleteTranslation}
                      size={"small"}
                      onClick={(e) => {
                        entry.id && deleteTranslation(entry.id);
                      }}
                    >
                      {t("components.card.delete")}
                    </PrimaryButton>
                  </TranslatedTextContainer>
                </CardContent>
              </TranslationCard>
            ))}
        </VocabularyList>
      </CheckIfConditionOrLoading>
    </Page>
  );
};
