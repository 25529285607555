import React from "react";

import { Chip, styled, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Direction } from "../../types/types";

export const ChipContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

//todo into mvp
const StyledChip = styled(Chip)(({ theme }) => ({}));

const StyledContainer = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const HeadlineContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export interface IChipSelectionProps<T> {
  filters: T[];
  selectedFilters: T[];
  onFilterClick: (filter: T[]) => void;
  multipleSelection: boolean;
  headline?: string;
  subHeadline?: string;
}

/**
 * <p> Chip Selection</p>
 *
 */
export function ChipSelection<T extends Direction>({
  filters,
  selectedFilters,
  onFilterClick,
  multipleSelection,
  headline,
  subHeadline,
}: IChipSelectionProps<T>) {
  const { t } = useTranslation();
  const handleFilterClick = (filter: T) => {
    if (multipleSelection) {
      // If multiple selection is allowed, toggle the filter's selection status
      const newSelectedFilters = selectedFilters.includes(filter)
        ? selectedFilters.filter((item) => item !== filter)
        : [...selectedFilters, filter];

      onFilterClick(newSelectedFilters);
    } else {
      // If multiple selection is not allowed, always set the clicked filter as the only selection
      onFilterClick([filter]);
    }
  };
  return (
    <div>
      <HeadlineContainer>
        {headline && <Typography variant={"h4"}>{headline}</Typography>}
        {subHeadline && (
          <Typography variant={"body2"} style={{ marginBottom: 20 }}>
            {subHeadline}
          </Typography>
        )}
      </HeadlineContainer>
      <ChipContainer>
        {filters.map((filter, index) => (
          <StyledChip
            key={index}
            label={`${t(`languageCode.${filter.from}`)} ->${t(
              `languageCode.${filter.to}`
            )}`}
            color="primary"
            variant={
              JSON.stringify(selectedFilters[0]) === JSON.stringify(filter)
                ? "filled"
                : "outlined"
            }
            onClick={() => handleFilterClick(filter)}
          />
        ))}
      </ChipContainer>
    </div>
  );
}
