import { createTheme } from "@mui/material";
import { blackTheme } from "@ppphiiil/mvp-system";
import { buttonsStyling } from "./button-styles";
import { colors } from "./colors";
import { typography } from "./typography";

export const customizedBlackTheme = createTheme({
  ...blackTheme,
  palette: {
    ...colors,
  },
  components: {
    ...buttonsStyling,
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
          color: colors.primary.main,
        },
      },
    },
  },
  typography: { ...typography },
  shape: { borderRadius: 14, rounded: 30 },
});
