import { getAuth } from "@firebase/auth";
import { useServices } from "../../services/service-provider";
import { useMutation } from "@tanstack/react-query";

export const useTextToSpeach = () => {
  const { translationService } = useServices();
  const auth = getAuth();
  return useMutation<void, Error, { text: string; language: string }>({
    mutationKey: ["text-to-speach"],
    mutationFn: async ({ text, language }) => {
      const audioSrc = await translationService.getVoiceFromGoogle(
        auth,
        text,
        language
      );

      if (audioSrc) {
        const audioEl = new Audio(audioSrc);
        await audioEl.play();
      }
    },
  });
};
