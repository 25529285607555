import { BottomMenuMobile } from "./BottomMenuMobile";
import { SideMenuContent } from "./SideMenu";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useUser } from "../../hooks/use-user";
import { Header } from "./Header";

const LayoutContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "red",
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
}));

const StyledSideMenuContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),

  display: "none",
  maxWidth: "250px",
  border: "1px solid black",
  width: "0",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0),
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  boxShadow: "0px 2px 10px 0px  rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  height: "60px",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
}));

const Offset = styled("div")(() => ({
  height: "60px",
}));

const Sidebar = styled("aside")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: 250,
  flexShrink: 0,
  backgroundColor: theme.palette.background.paper,
  borderRight: "1px solid",
  borderColor: theme.palette.grey.A200,
  padding: theme.spacing(2),
}));

const StyledHeaderContainer = styled("div")(({ theme }) => ({
  flexDirection: "column",
  display: "flex",
  height: 60,
  width: "100%",
  zIndex: 1000,
  boxShadow: "0px 2px 10px 0px  rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("md")]: {
    height: 100,
  },
}));

const StyledBottomMenuMobileContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 2px 10px 0px  rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const Main = styled("main")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  overflowX: "hidden",
}));

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",

  backgroundColor: theme.palette.background.paper,
}));

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
  maxHeight: "100%",
  overflow: "hidden",
}));

interface Props {
  children: React.ReactNode;
  withoutSidebar?: boolean;
}

export const Layout = ({ children, withoutSidebar = false }: Props) => {
  const theme = useTheme();
  const { user, isLoading } = useUser();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  if (isLoading) {
    return null;
  }
  return (
    <Root>
      {(!user || (user && isMdUp)) && (
        <StyledHeaderContainer>
          <Header />
        </StyledHeaderContainer>
      )}
      <Main>
        {isMdUp && !withoutSidebar && user && (
          <Sidebar>
            <SideMenuContent />
          </Sidebar>
        )}

        <Content>{children}</Content>
      </Main>
      <StyledBottomMenuMobileContainer>
        <BottomMenuMobile />
      </StyledBottomMenuMobileContainer>
    </Root>
  );
};

/*
<LayoutContainer>
  {/!*HEADER*!/}
  {isMdUp && <Offset />}

  {/!*SIDE MENU*!/}
  {isMdUp ? (
          <HeaderContainer>
            <Header />
          </HeaderContainer>
  ) : (
          <BottomMenuMobile />
  )}

  <ContentContainer>
    {/!*CONTENT PAGES*!/}
    {/!*{isMdUp && user ? (
          <StyledSideMenuContainer>
            <SideMenuContent />
          </StyledSideMenuContainer>
        ) : (
          <DrawerSideMenuContainer>
            <StyledSideMenuContainer>
              <SideMenuContent />
            </StyledSideMenuContainer>
          </DrawerSideMenuContainer>
        )}*!/}
    <RoundedContainer>
      <PageContainer>{children}</PageContainer>
    </RoundedContainer>
  </ContentContainer>
</LayoutContainer>*/
