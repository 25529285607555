export const getBrowser = (): "safari" | "chrome" | undefined => {
  console.log(
    "getBrowserWithAgentAndFeatures()",
    getBrowserWithAgentAndFeatures()
  );
  console.log(
    "getBrowserWithAgentAndFeatures() navigator.userAgent",
    navigator.userAgent
  );
  switch (getBrowserWithAgentAndFeatures()) {
    case "safari":
      return "safari";
    case "chrome":
      return "chrome";
    default:
      return undefined;
  }
};

// this function combines user.agent and feature from browsers, and gives back the browser
const getBrowserWithAgentAndFeatures = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "opera";
  } else if (
    Boolean(
      /* (window as any).chrome is definitely chrome*/
      (window as any).chrome ||
        navigator.userAgent.indexOf("Chrome") != -1 ||
        navigator.userAgent.indexOf("CriOS") != -1
    )
  ) {
    return "chrome";
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    return "edge";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "safari";
  } else if (
    navigator.userAgent.indexOf("Firefox") != -1 &&
    "webkitLineBreak" in document.documentElement.style === false
  ) {
    return "firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!(document as any).documentMode
  ) {
    //IF IE > 10
    return "iexplorer";
  } else {
    return "unknown";
  }
};
/*todonext features einbauen und testen*/
const logFeatures = () => {
  console.log(
    "getBrowserWithAgentAndFeatures",
    getBrowserWithAgentAndFeatures()
  );
  console.log("------ START");
  //################### CHROME
  console.log("------ only in chrome should be true:");
  //################### CHROME

  console.log(
    `${
      navigator.userAgent.indexOf("Chrome") !== -1
    } navigator.userAgent.indexOf("Chrome") != -1`
  );
  console.log(`${Boolean((window as any).chrome)} window.chrome`);

  console.log("------ only in chrome should be false:");

  //################### FIREFOX
  console.log("------ only in firefox should be false:");
  //################### FIREFOX
  console.log(
    `${"webkitLineBreak" in document.documentElement.style} webkitLineBreak`
  );
  console.log("------ only in firefox should be true:");

  //################### SAFARI
  console.log("------ only in safari should be true:");
  //################### SAFARI
  console.log(
    `${Boolean(
      !!(window as any).ApplePaySetupFeature
    )} !!window.ApplePaySetupFeature`
  );
  console.log(
    `${
      navigator.userAgent.indexOf("Safari") != -1
    } navigator.userAgent.indexOf("Safari") != -1`
  );
  console.log(
    `${
      "safari" in window && "pushNotification" in (window as any).safari
    } hasSafariReader`
  );

  console.log("------ only in safari should be false:");
  console.log(`${"PaymentRequest" in window} PaymentRequest' in window`);
  console.log("------ END");
};

function isSafariLikely() {
  // 1. Check for WebKit-prefixed properties (suggests WebKit engine)
  const isWebKit = "webkitSpeechRecognition" in window;

  // 2. Check for absence of more specific Chrome features
  const isNotChrome = isChromeLikely();

  // 3. Check for Safari-specific features
  const isSafari =
    !isNotChrome &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome");

  // 4. Heuristic: Combine checks with weights for reliability
  return (isWebKit ? 2 : 0) + (isNotChrome ? 1 : 0) + (isSafari ? 2 : 0) >= 4;
}

function isChromeLikely() {
  // 1. Check for "chrome" object existence and its specific properties
  const isChrome = typeof window.chrome !== "undefined";

  // 2. Check for userAgent string containing "Chrome"
  const isChromeUserAgent =
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.includes("Chrome");

  // 2. Check for Chrome-specific features
  const isChromeFeatures =
    "AudioContext" in window ||
    "webkitAudioContext" in window ||
    "SpeechRecognition" in window ||
    "webkitSpeechRecognition" in window;

  console.log("isChrome", isChrome);
  console.log("isChromeUserAgent", isChromeUserAgent);
  // 3. Heuristic: Combine checks with weights for reliability
  return (
    (isChrome ? 2 : 0) +
      (isChromeUserAgent ? 1 : 0) +
      (isChromeFeatures ? 1 : 0) >=
    2
  );
}
