export enum LanguageCodeType {
  en = "en",
  es = "es",
  pt = "pt",
  ru = "ru",
  fr = "fr",
  sk = "sk",
  de = "de",
  it = "it",
  pl = "pl",
  uk = "uk",
  nl = "nl",
  tr = "tr",
  ro = "ro",
  cs = "cs",
  sv = "sv",
  sr = "sr",
  hr = "hr",
  da = "da",
  no = "no",
  fi = "fi",
  el = "el",
  hu = "hu",
  bg = "bg",
  ca = "ca",
  he = "he",
  ka = "ka",
  ga = "ga",
  is = "is",
  mt = "mt",
  bs = "bs",
  sq = "sq",
  cy = "cy",
  eu = "eu",
  bn = "bn",
  vi = "vi",
  ko = "ko",
  zh = "zh",
  ar = "ar",
  hi = "hi",
  ja = "ja",
  th = "th",
  ta = "ta",
  te = "te",
  mr = "mr",
}

export type LanguageCodes = keyof typeof LanguageCodeType;

export const allLanguagesObject = {
  en: {
    language: "Englisch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  es: {
    language: "Spanisch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  pt: {
    language: "Portugiesisch (Portugal, Brasilien)",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  ru: {
    language: "Russisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  fr: {
    language: "Französisch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  de: {
    language: "Deutsch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  it: {
    language: "Italienisch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  pl: {
    language: "Polnisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  uk: {
    language: "Ukrainisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  nl: {
    language: "Niederländisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  tr: {
    language: "Türkisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  ro: {
    language: "Rumänisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  cs: {
    language: "Tschechisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  sv: {
    language: "Schwedisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  sr: {
    language: "Serbisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  hr: {
    language: "Kroatisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  da: {
    language: "Dänisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  no: {
    language: "Norwegisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  sk: {
    language: "Slowakisch",
    direction: "LTR",
    inEurope: true,
    online: true,
  },
  fi: {
    language: "Finnisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  el: {
    language: "Griechisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  hu: {
    language: "Ungarisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  bg: {
    language: "Bulgarisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  ca: {
    language: "Katalanisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  he: {
    language: "Hebräisch",
    direction: "RTL",
    inEurope: true,
    online: false,
  },
  ka: {
    language: "Georgisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  ga: {
    language: "Irisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  is: {
    language: "Islandisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  mt: {
    language: "Maltisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  bs: {
    language: "Bosnisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  sq: {
    language: "Albanisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  cy: {
    language: "Walisisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  eu: {
    language: "Baskisch",
    direction: "LTR",
    inEurope: true,
    online: false,
  },
  bn: {
    language: "Bengalisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  vi: {
    language: "Vietnamesisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  ko: {
    language: "Koreanisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  zh: {
    language: "Chinesisch (vereinfacht)",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  ar: {
    language: "Arabisch",
    direction: "RTL",
    inEurope: false,
    online: false,
  },
  hi: {
    language: "Hindi",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  ja: {
    language: "Japanisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  th: {
    language: "Thailändisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  ta: {
    language: "Tamil",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  te: {
    language: "Telugu",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  mr: {
    language: "Marathi",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  gu: {
    language: "Gujarati",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  ml: {
    language: "Malayalam",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  kn: {
    language: "Kannada",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  pa: {
    language: "Pandschabi",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
  si: {
    language: "Singhalesisch",
    direction: "LTR",
    inEurope: false,
    online: false,
  },
};
