import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface Props {
  isLoading: boolean;
  condition: boolean;
  showElementIfCondition: JSX.Element;
  children: JSX.Element;
}
export const CheckIfConditionOrLoading = ({
  isLoading,
  condition,
  showElementIfCondition,
  children,
}: Props) => {
  return (
    <>
      {isLoading ? (
        condition ? (
          children
        ) : (
          showElementIfCondition
        )
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
