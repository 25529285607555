import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { TextToSpeachButton } from "./PlaySoundButton/TextToSpeachButton";

const Face = styled.div`
  font-size: 1.5em;
  position: absolute;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  gap: 50px;
`;

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
`;
/*todo mit mui styled*/
const Card = styled.div<{
  rotated: boolean;
}>`
  position: absolute;

  box-shadow: 0px 4px 4px #e0e1e3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #c6c7c9;
  border-radius: 20px;
  cursor: grab;
  transform-style: preserve-3d;

  transition: transform 0.2s;
  transform: ${(props) =>
    props.rotated ? `rotateY(180deg) ` : `rotateY(0deg)`};
`;

const WordFace = styled(Face)`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  background-color: #f5f6f6;
`;

const TranslationFace = styled(Face)`
  display: flex;
  flex-direction: column;
  color: #555;
  transform: rotateY(180deg);
  background-color: #ffffff;
`;

export interface FlashcardProps {
  word: string;
  wordLang: string;
  translation: string;
  translationLang: string;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

export const FlashCard: React.FC<FlashcardProps> = ({
  word,
  wordLang,
  translation,
  translationLang,
  onSwipeLeft,
  onSwipeRight,
}) => {
  const [rotated, setRotated] = useState(false);
  const [startX, setStartX] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleClick = () => {
    setRotated(!rotated);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const currentX = e.touches[0].clientX;
    const diffX = currentX - startX;
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const endX = e.changedTouches[0].clientX;
    const diffX = endX - startX;

    if (diffX > 50 && onSwipeLeft) {
      onSwipeLeft();
    } else if (diffX < -50 && onSwipeRight) {
      onSwipeRight();
    }
  };
  return (
    <CardContainer>
      <Card
        onClick={handleClick}
        rotated={rotated}
        ref={cardRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <WordFace>
          {/*wordLang.length === 2 because we chaeck if it is a languageCode with 2 */}
          {wordLang.length === 2 ? (
            <Typography style={{ paddingTop: 8 }} variant={"body2"}>{`${t(
              `languageCode.${wordLang}`
            )}`}</Typography>
          ) : null}
          <Typography
            style={{
              display: "flex",
              fontSize: "1.7rem",
              flex: 1,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
            variant={"body1"}
          >
            {word}
          </Typography>
          <ActionContainer>
            <TextToSpeachButton text={word} language={wordLang} />
            <IconButton>
              <VisibilityOutlinedIcon />
            </IconButton>
          </ActionContainer>
        </WordFace>
        <TranslationFace>
          {/*wordLang.length === 2 because we chaeck if it is a languageCode with 2 */}
          {wordLang.length === 2 ? (
            <Typography style={{ paddingTop: 8 }} variant={"body2"}>{`${t(
              `languageCode.${translationLang}`
            )}`}</Typography>
          ) : null}
          <Typography
            style={{
              display: "flex",
              flex: 1,
              fontSize: "1.7rem",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
            variant={"body1"}
          >
            {translation}
          </Typography>
          <ActionContainer>
            <TextToSpeachButton text={translation} language={translationLang} />
            <IconButton>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          </ActionContainer>
        </TranslationFace>
      </Card>
    </CardContainer>
  );
};
