// @ts-ignore
//todo font funktioniert nicht oder?
export const breakpoint = 500;

export const typography = {
  fontSize: 16,
  fontFamily: [
    "Poppins-Regular",
    "Poppins",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontFamily: "Poppins-ExtraBold",
    fontSize: "2.2rem",
    color: "#313452",
    letterSpacing: 0,
    marginBottom: 20,
    lineHeight: 1.1,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "3.5rem",
    },
  },
  h2: {
    fontFamily: "Poppins-ExtraBold",
    fontSize: "1.6rem",
    color: "#313452",
    letterSpacing: -1,
    lineHeight: 1.1,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "2rem",
    },
  },
  h3: {
    fontFamily: "Poppins-SemiBold",
    letterSpacing: "-0.5px",
    fontSize: "1.3rem",
    color: "#313452",
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "1.6rem",
    },
  },
  h4: {
    fontFamily: "Poppins-ExtraBold",
    fontSize: "1.2rem",
    letterSpacing: "-1px",
    fontWeight: 900,
    color: "#313452",
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "1.4rem",
    },
  },
  body1: {
    fontFamily: "Poppins-Regular",
    fontSize: "1rem",
    color: "#313452",
    lineHeight: 1.35,
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "1rem",
    },
  },
  body2: {
    fontFamily: "Poppins-Regular",
    fontSize: "1rem",
    lineHeight: 1.35,
    color: "rgb(88,83,105)",
    [`@media (min-width:${breakpoint}px)`]: {
      fontSize: "1.2rem",
    },
  },
};
