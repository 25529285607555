import * as React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  useMediaQuery,
} from "@mui/material";
import MUIDialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";

interface Props {
  children: JSX.Element;
  actions?: () => JSX.Element;
  contentPadding?: number;
  isOpen: boolean;
  transition?: React.ForwardRefExoticComponent<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    } & React.RefAttributes<unknown>
  >;
  onClose: () => void;
  title?: string;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialog = ({
  children,
  actions,
  contentPadding,
  transition,
  isOpen,
  onClose,
  title,
  ...props
}: Props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={transition ?? Transition}
      fullScreen={!isMdUp}
      {...props}
    >
      {/*hack for a maxWidth Dialog*/}
      {isMdUp && <div style={{ minWidth: 600, width: "100%" }}> </div>}
      {title && (
        <>
          <DialogTitle
            variant={"h2"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title}
            {/*    <IconButton onClick={onClose}>
              <CloseIcon fontSize={"large"} />
            </IconButton>*/}
          </DialogTitle>
        </>
      )}
      {title && <Divider />}
      <DialogContent
        sx={(theme) => ({
          padding: theme.spacing(contentPadding ?? 0),
          paddingTop: theme.spacing(0),
        })}
      >
        {children}
      </DialogContent>

      {actions && <DialogActions>{actions()}</DialogActions>}
    </MUIDialog>
  );
};
