import { SideMenuContent } from "./SideMenu";
import { DrawerSideMenuContainer } from "./DrawerSideMenuContainer";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SwipeableDrawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import FontLogo from "../../resources/assets/logo/fontlogo2.png";
import { LogoContainer } from "./Header";
import MenuIcon from "@mui/icons-material/Menu";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import { useNavigate } from "react-router-dom";
import { HOME, LEARN, VOCABULARY } from "../../routing/routes";
import { useUser } from "../../hooks/use-user";
import { useTranslation } from "react-i18next";

const StyledDrawerLogo = styled("img")(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-start",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  height: 30,
}));

const StyledMenuLogo = styled("img")(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  height: 30,
}));

const DrawerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
}));

const CloseIconContainer = styled("div")(() => ({
  alignSelf: "flex-end",
}));

const StyledIconButton = styled(IconButton)(() => ({
  fontSize: "small",
  display: "flex",
  flexDirection: "column",
}));

const StyledIconLabel = styled(Typography)(() => ({
  fontSize: "small",
  padding: 0,
}));

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",

  flexDirection: "row",

  width: "100%",
  justifyContent: "space-evenly",
  backgroundColor: theme.palette.background.paper,
  zIndex: 100,
  padding: theme.spacing(0, 1),
}));

export const BottomMenuMobile = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  const { t } = useTranslation();
  return (
    <>
      <StyledContainer>
        {user && (
          <>
            <StyledIconButton
              color="primary"
              onClick={() => navigate(HOME, { replace: true })}
            >
              <HomeRoundedIcon />
              <StyledIconLabel>{t("page.home.sideMenu")}</StyledIconLabel>
            </StyledIconButton>
            <StyledIconButton
              color="primary"
              onClick={() => navigate(VOCABULARY, { replace: true })}
            >
              <LayersRoundedIcon />
              <StyledIconLabel>{t("page.vocabulary.sideMenu")}</StyledIconLabel>
            </StyledIconButton>
            <StyledIconButton
              color="primary"
              onClick={() => navigate(LEARN, { replace: true })}
            >
              <SchoolRoundedIcon />
              <StyledIconLabel>{t("page.learnMode.sideMenu")}</StyledIconLabel>
            </StyledIconButton>
          </>
        )}
        <StyledIconButton color="primary" onClick={toggleDrawer}>
          <MenuIcon />
          <StyledIconLabel>{t("components.sideMenu.more")}</StyledIconLabel>
        </StyledIconButton>
      </StyledContainer>
      <SwipeableDrawer open={open} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <DrawerContainer>
          <CloseIconContainer>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon fontSize={"large"} />
            </IconButton>
          </CloseIconContainer>
          <DrawerSideMenuContainer>
            <LogoContainer>
              {/*   <StyledDrawerLogo src={LogoPng} />*/}
              <StyledDrawerLogo src={FontLogo} />
            </LogoContainer>
            <SideMenuContent closeDrawer={toggleDrawer} />
          </DrawerSideMenuContainer>
        </DrawerContainer>
      </SwipeableDrawer>
    </>
  );
};
