import React from "react";
import { useTranslation } from "react-i18next";
import { LearnLanguageChangeDropdown } from "../LearnLanguageChangeDropdown/LearnLanguageChangeDropdown";
import { SettingItem } from "./SettingItem";

interface Props {}
export const LearnLanguageSetting = ({}: Props) => {
  const { t } = useTranslation();

  return (
    <SettingItem name={t("dialog.settings.learnLanguage")}>
      <LearnLanguageChangeDropdown />
    </SettingItem>
  );
};
