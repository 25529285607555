import { useTranslation } from "react-i18next";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Box, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { HomeScreenSvg } from "../../resources/assets/svg";
import { CenteredContainer } from "../ui/containers";

import { isMobileDevice } from "../../utils/detect-mobile-device";
import { getBrowser } from "../../utils/detect-browser";

const ModalContentMobile = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: "100%",
  maxWidth: "400px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const ModalContentDesktop = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "stretch",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  maxWidth: "400px",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const Step = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
}));

interface Props {
  onOpen: boolean;
  onClose: () => void;
}

export const IOsAddToHomescreenInstructionModal = ({
  onOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const browser = getBrowser();
  const isMobile = isMobileDevice();
  console.log("browser", browser);
  console.log("isMobile", isMobile);
  const browserAndDevice = browser + " " + (isMobile ? "mobile" : "desktop");
  let modalContent = null;

  const showBrowserInfos = () => {
    return (
      <div style={{ marginTop: 50 }}>
        <Typography sx={{ fontSize: 8 }} variant={"caption"}>
          {"Detected Browser: " +
            browserAndDevice +
            " / " +
            navigator.userAgent}
        </Typography>
      </div>
    );
  };

  switch (browserAndDevice) {
    case "safari mobile":
      modalContent = (
        <>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t("common.addToHomeScreenModal.title")}
          </Typography>
          <Step>
            <Typography variant={"caption"}>
              {t("common.addToHomeScreenModal.firstStep")}
            </Typography>
            <IosShareIcon
              sx={(theme) => ({
                marginLeft: theme.spacing(1),
              })}
              fontSize={"small"}
            />
          </Step>

          <Step>
            <Typography variant={"caption"}>
              {t("common.addToHomeScreenModal.secondStep")}
            </Typography>
            <HomeScreenSvg
              style={{ marginLeft: "10px" }}
              fill={"black"}
              height={"20px"}
            />
          </Step>
          <CenteredContainer>
            <ArrowDownwardIcon sx={{ mt: 3 }} />
          </CenteredContainer>
        </>
      );
      break;
    case "chrome mobile":
      console.log("chrome mobile");
      modalContent = (
        <>
          <CenteredContainer>
            <ArrowUpwardIcon sx={{ mb: 3 }} />
          </CenteredContainer>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t("common.addToHomeScreenModal.title")}
          </Typography>
          <Step>
            <Typography variant={"caption"}>
              {t("common.addToHomeScreenModal.firstStep")}
            </Typography>
            <IosShareIcon
              sx={(theme) => ({
                marginLeft: theme.spacing(1),
              })}
              fontSize={"small"}
            />
          </Step>

          <Step>
            <Typography variant={"caption"}>
              {t("common.addToHomeScreenModal.secondStep")}
            </Typography>
            <HomeScreenSvg
              style={{ marginLeft: "10px" }}
              fill={"black"}
              height={"20px"}
            />
          </Step>
        </>
      );
      break;
    case "undefined mobile":
      console.log("chrome mobile");
      modalContent = (
        <>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t("common.addToHomeScreenModal.pleaseChangeToSafari")}
          </Typography>
        </>
      );
      break;
    default:
      modalContent = (
        <>
          <Step>
            <Typography variant={"caption"}>
              {t("common.addToHomeScreenModal.openOnSmartphone")}
            </Typography>
          </Step>
        </>
      );
      break;
  }

  return (
    <Modal open={onOpen} onClose={onClose}>
      {isMobileDevice() ? (
        <>
          <ModalContentMobile>
            <>
              {modalContent}
              {showBrowserInfos()}
            </>
          </ModalContentMobile>
        </>
      ) : (
        <>
          <ModalContentDesktop>{modalContent}</ModalContentDesktop>
          {showBrowserInfos()}
        </>
      )}
    </Modal>
  );
};
