import { alpha, darken, Theme } from "@mui/material/styles";
import { Components } from "@mui/material/styles/components";

export const buttonsStyling: Components<Omit<Theme, "components">> = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...{
          boxShadow: `none`,
          textTransform: "none",
          fontWeight: "bold",
          borderRadius: 10,
          padding: theme.spacing(1.5, 5),
          minWidth: "100px",
          fontSize: "1rem",
          //fontSize: "1rem",

          "&:hover": {
            boxShadow: `none`,
            textDecoration: "underline",
          },
        },
      }),
      containedPrimary: ({ ownerState, theme }) => ({
        ...{
          boxShadow: `0 15px 25px ${alpha(theme.palette.primary.main, 0.3)}`,
          textTransform: "none",
          fontWeight: "bold",
          borderRadius: 10,
          padding: theme.spacing(1.5, 5),
          minWidth: "100px",
          fontSize: "1rem",

          //fontSize: "1rem",
          "&:hover": {
            boxShadow: `0 15px 40px ${alpha(theme.palette.primary.main, 0.5)}`,
            backgroundColor: darken(theme.palette.primary.main, 0.2),
            textDecoration: "none",
          },
        },
      }),

      sizeSmall: ({ ownerState, theme }) => ({
        fontSize: "0.8rem",
        padding: theme.spacing(0.5, 3),
        minWidth: "50px",
        borderRadius: 6,
      }),
    },
  },
};
