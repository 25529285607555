import { Direction, Translation } from "../../types/types";
import { API_URL } from "../../index";
import { Auth } from "@firebase/auth";
import {
  LanguageCodes,
  LanguageCodeType,
} from "../../components/shared/LanguageButton/language-code/languages";

export enum LearnStatus {
  ALL = "All",
}
export interface TranslationInfos {
  all: number;
}

export class TranslationService {
  constructor() {}
  async fetchTranslationsInfos(auth: Auth): Promise<TranslationInfos> {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      try {
        const response = await fetch(`${API_URL}/translation/infos`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          throw new Error("no response");
        }
      } catch (error) {
        console.log("Error while fetching translation infos", error);
        throw new Error("no response");
      }
      /*todo this else is wrong use TS for response above*/
    } else {
      throw new Error("no infos");
    }
  }

  async fetchTranslations(
    auth: Auth,
    filter: LearnStatus | undefined
  ): Promise<Translation[]> {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      //get Translations from database
      //todo filter api filter
      try {
        const response = await fetch(`${API_URL}/translation`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          return data.translations;
        } else {
          throw new Error("no response"); //todo is no rückmeldung von server
        }
      } catch (error) {
        console.log("Error while fetching wordList", error);
        throw new Error("no response");
      }
    } else {
      throw new Error("no token");
    }
  }

  async saveTranslation(
    auth: Auth,
    word: string,
    wordLang: string,
    translation: string,
    translationLang: string
  ): Promise<void> {
    await fetch(`${API_URL}/translation`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ word, wordLang, translation, translationLang }),
    });
  }

  async saveSetting(
    auth: Auth,
    param: Record<string, string | boolean>
  ): Promise<void> {
    await fetch(`${API_URL}/settings/save`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    });
  }

  async fetchSetting(auth: Auth, settingName: string): Promise<any> {
    const token = await auth.currentUser?.getIdToken();

    if (token) {
      try {
        const response = await fetch(`${API_URL}/settings/${settingName}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(`fetchSetting response: ${settingName}`, response);
        if (response.ok) {
          const data = await response.json();
          return data.data[settingName];
        } else {
          return undefined;
          console.log("no settings found", settingName);
        }
      } catch (error) {
        console.log("Error while fetching settings", error);
        throw new Error("Error while fetching settings");
      }
    } else {
      throw new Error("no token");
    }
  }

  async deleteTranslations(auth: Auth, idToDelete: string): Promise<void> {
    await fetch(`${API_URL}/translation`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idToDelete: idToDelete }),
    });
  }

  async getTranslationFromGoogle(
    auth: Auth,
    word: string,
    { from: sourceLanguage, to: targetLanguage }: Direction
  ): Promise<string | null> {
    let translatedText: string | null = null;

    /***
     * version 1 works from package translate
     * */
    /* const translation = await translate(word, {
      from: sourceLanguage,
      to: targetLanguage,
    });*/

    /* const response = await translate(word, {
      to: targetLanguage,
      forceBatch: false,
    });*/

    //illegal
    /*   const response = await fetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&dt=t&dt=bd&dt=qc&dt=rm&dt=ex&sl=${sourceLanguage}&tl=${targetLanguage}&q=${word}&dj=1&tk=911885.911885&&ie=UTF-8&oe=UTF-8`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          charset: "utf-8",
        },
      }
    );
*/
    try {
      // fetch translation
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyBp4GH4wkapds9kwtGo86REGy4fA8RuXz4&source=${sourceLanguage}&target=${targetLanguage}&q=${word}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseJSON = await response.json();
        if (responseJSON.data.translations.length > 1) {
          window.alert("More responseJSON.data.translations.length>1");
        }
        translatedText = responseJSON.data.translations[0].translatedText;
      }
    } catch (error) {
      console.log("Error text->", error);
    }

    //translate.googleapis.com/translate_a/single?dt=t&dt=bd&dt=qc&dt=rm&dt=ex&client=gtx&hl=en&sl=en&tl=de&q=benefit&dj=1&tk=911885.911885

    //https://api.dictionaryapi.dev/api/v2/entries/en/<word>

    return translatedText;
  }

  async getVoiceFromGoogle(
    auth: Auth,
    word: string,
    language: string
  ): Promise<string | undefined> {
    let base64string: string | null = null;

    //de is ISO639-1 de-US
    const secondPartOfLanguageCode =
      languageToCountryMap[language as LanguageCodes][0];

    const getGoogleKey = () => {
      if (process.env.REACT_APP_NODE_ENV === "production") {
        return process.env.REACT_APP_GOOGLE_KEY;
      }
      if (process.env.REACT_APP_NODE_ENV === "development") {
        return process.env.REACT_APP_GOOGLE_KEY_LOCAL;
      }
    };

    const googleKey = getGoogleKey();
    //fetch audio
    //todo mehr voices https://cloud.google.com/text-to-speech/docs/reference/rest/v1/voices/list
    //todo hook erstellen
    const responseAudio = await fetch(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${googleKey}`,
      {
        method: "POST",
        body: JSON.stringify({
          input: {
            text: word,
          },
          voice: {
            languageCode: language,
            name: `${language}-${secondPartOfLanguageCode}-Standard-A`,
            ssmlGender: "FEMALE",
          },
          audioConfig: {
            audioEncoding: "MP3",
          },
        }),
      }
    );
    if (responseAudio.ok) {
      const responseAudioJSON: { audioContent: string } =
        await responseAudio.json();
      base64string = decodeBase64Audio(responseAudioJSON.audioContent);
      return base64string;
    }
  }
  catch(error: any) {
    console.log("Error audio ->", error);
  }
}

const decodeBase64Audio = (base64AudioContent: string): string => {
  const binaryAudio = atob(base64AudioContent);
  const arrayBuffer = new ArrayBuffer(binaryAudio.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryAudio.length; i++) {
    uint8Array[i] = binaryAudio.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "audio/mpeg" });
  const audioSrc = URL.createObjectURL(blob);
  return audioSrc;
};

export const languageToCountryMap: Record<LanguageCodeType, string[]> = {
  en: [
    "US",
    "GB",
    "CA",
    "AU",
    "NZ",
    "IE",
    "ZA",
    "IN",
    "SG",
    "PH",
    "MY",
    "NG",
    "PK",
    "KE",
    "GH",
    "TZ",
    "UG",
    "AE",
    "IL",
    "ZW",
    "RW",
    "UG",
    "BD",
    "LK",
    "JM",
    "TT",
    "BS",
    "FJ",
    "GY",
    "ZW",
    "ZM",
    "BW",
    "BN",
    "MS",
    "FK",
    "BM",
    "VG",
    "KY",
    "CC",
    "CX",
    "GG",
    "GI",
    "IM",
    "JE",
    "IO",
    "FK",
    "MT",
    "MU",
    "MS",
    "NR",
    "NU",
    "NF",
    "PN",
    "SH",
    "BL",
    "MF",
    "GS",
    "SS",
    "TC",
    "TK",
    "TO",
    "TV",
    "VC",
    "WF",
    "WS",
  ],
  es: [
    "ES",
    "MX",
    "AR",
    "CO",
    "CL",
    "PE",
    "VE",
    "EC",
    "GT",
    "CU",
    "BO",
    "DO",
    "HN",
    "PY",
    "SV",
    "NI",
    "CR",
    "PR",
    "PA",
    "UY",
    "GQ",
  ],
  sk: ["SK"],
  pt: ["PT", "BR", "MZ", "AO", "GW", "TL", "CV"],
  ru: [
    "RU",
    "BY",
    "KZ",
    "KG",
    "AM",
    "AZ",
    "MD",
    "UZ",
    "TJ",
    "GE",
    "LV",
    "LT",
    "EE",
  ],
  fr: [
    "FR",
    "CA",
    "BE",
    "CH",
    "LU",
    "MC",
    "MQ",
    "GP",
    "GF",
    "RE",
    "YT",
    "PM",
    "WF",
    "BL",
    "MF",
    "NC",
    "PF",
    "TF",
  ],
  de: ["DE", "AT", "CH", "LI", "LU", "BE", "IT", "DK", "FR", "PL", "NL"],
  it: ["IT", "CH", "SM", "VA"],
  pl: ["PL"],
  uk: ["UA"],
  nl: ["NL", "BE", "SR"],
  tr: ["TR", "CY"],
  ro: ["RO", "MD"],
  cs: ["CZ"],
  sv: ["SE", "FI"],
  sr: ["RS", "BA", "ME", "XK"],
  hr: ["HR", "BA", "ME"],
  da: ["DK"],
  no: ["NO"],
  fi: ["FI"],
  el: ["GR", "CY"],
  hu: ["HU"],
  bg: ["BG"],
  ca: ["CA"],
  he: ["IL"],
  ka: ["GE"],
  ga: ["IE"],
  is: ["IS"],
  mt: ["MT"],
  bs: ["BS"],
  sq: ["AL", "MK"],
  cy: ["CY"],
  eu: ["ES"],
  bn: ["BD"],
  vi: ["VN"],
  ko: ["KR"],
  zh: ["CN", "TW", "SG"],
  ar: [
    "SA",
    "EG",
    "IQ",
    "DZ",
    "SD",
    "MA",
    "SY",
    "YE",
    "TN",
    "JO",
    "AE",
    "LB",
    "LY",
    "KW",
    "OM",
    "QA",
    "BH",
  ],
  hi: ["IN", "FJ"],
  ja: ["JP"],
  th: ["TH"],
  ta: ["IN", "LK", "SG", "MY"],
  te: ["IN"],
  mr: ["IN"],
};
